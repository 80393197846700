<template>
	<template v-if="isAllowed">
		<div class="extended_check mb-3" :class="hasExtraItems ? 'active' : ''">
			<div class="row gy-y justify-content-between align-items-center">
				<div class="col">
					<div class="form-check mb-3 mb-md-0">
						<!-- <input @change.prevent="check" class="form-check-input ml-2" type="checkbox" :checked="theModel.quantity > 0"> -->

						<div class="form-check-label ps-3">
							<a
								@click.prevent="modal_visible = true"
								v-if="item.name"
								href="#"
								class="d-block fw-bold text_20 mb-2"
								:class="hasExtraItems ? 'text-success' : ''"
								>{{ item.name }}</a
							>

							<p v-if="item.short_description" class="mb-2">
								{{ item.short_description }}
							</p>

							<p v-if="hasExtraItems" class="text-success fw-bold mb-0">
								{{ item.type == 'default' ? serviceExtraItems[0].quantity : serviceExtraItems.length }} x {{ item.price }} ₽
							</p>
						</div>
					</div>
				</div>
				<div class="ps-5 ps-md-0 col-md-3">
					<p class="mb-1 text-md-center">
						<span class="fw-bold">{{ item.price }} ₽</span>
						<span class="text-muted">/ {{ item.measure ? item.measure : 'услуга' }}</span>
					</p>

					<a
						v-if="hasExtraItems"
						@click.prevent="modal_visible = true"
						href="#"
						class="btn btn-warning btn-rounded"
						>Изменить</a
					>
					<a
						v-else
						@click.prevent="modal_visible = true"
						href="#"
						class="btn btn-primary btn-primary-shadow btn-rounded"
						>Добавить</a
					>
				</div>
			</div>
		</div>
		<UpsellForDistancesModal
			v-if="item.type == 'for_distances'"
			:item="item"
			:visible="modal_visible"
			@hide="modal_visible = false" />
		<UpsellForAthletesModal 
            v-if="item.type == 'for_athletes'"
            :item="item"
            :visible="modal_visible"
            @hide="modal_visible = false"
        />
        <UpsellDefaultModal 
            v-if="item.type == 'default'"
            :item="item"
            :visible="modal_visible"
            @hide="modal_visible = false"
        />
	</template>
</template>

<script>
import UpsellDefaultModal from '../../offcanvases/UpsellDefaultModal.vue'
import UpsellForAthletesModal from '../../offcanvases/UpsellForAthletesModal.vue'
import UpsellForDistancesModal from '../../offcanvases/UpsellForDistancesModal.vue'
import { mapGetters } from 'vuex'

export default {
	props: {
		item: {
			type: Object,
		},
	},
	data() {
		return {
			modal_visible: false,
		}
	},
	inject: {
		extra_items: {
			from: 'extra_items',
			default: () => [],
		},
	},
	computed: {
		serviceExtraItems() {
			return this.extra_items.filter(
				(extra_item) => extra_item.additional_service_id == this.item.id
			)
		},
		hasExtraItems() {
			return this.serviceExtraItems.length > 0
		},
		isAllowed() {
			if (this.item.type != 'for_distances') return true
			if (!this.item?.for_distances || !this.item.for_distances?.length) return true
			return this.DISTANCE_ITEMS.filter(
				(item) => item.is_payed || item.reservation_status == 'reserved'
			).some((r) => this.item.for_distances.indexOf(r.distance_length.toString()) >= 0)
		},
		...mapGetters({
			DISTANCES: 'events/DISTANCES',
			DISTANCE_ITEMS: 'order/DISTANCE_ITEMS',
		}),
	},
	methods: {
		check(e, type) {
			if (e.target.checked === true) {
				//this.theModel.quantity = 1
				this.modal_visible = true
			} else {
				this.theModel.quantity = 0
			}
		},
	},
	components: {
		UpsellForDistancesModal,
		UpsellDefaultModal,
		UpsellForAthletesModal,
	},
}
</script>

<style></style>
