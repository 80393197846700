<template>
	<div v-if="athlete" class="athlete_checkboxes__item mb-2">
		<div
            @click="changeCheckbox"
			class="athlete_checkboxes__header d-flex justify-content-between align-items-center p-3 cursor-pointer">
			<div class="table_name_item d-flex align-items-center">
				<Avatar :name="athlete.name" :src="athlete.avatar" :class="'me-2'" />
				<div class="name">
					<span class="fw-bold" :class="checked ? 'text-success' : ''">{{ athlete.name }}</span>
					<span class="d-block text-muted small">{{ athlete.birthdate }} г.р</span>
				</div>
			</div>
			<input
                ref="checkbox"
				:checked="checked"
				type="checkbox"
				class="form-check-input ml-2" />
		</div>
	</div>
</template>

<script>
import Avatar from '../../UI/Avatar.vue'
import {mapGetters} from 'vuex'
export default {
    props: {
        additional_service_id: {
            type: [Number, String],
            required: true,
        },
        athlete_id: {
            type: [Number, String],
            required: true,
        },
    },
    inject: {
        extra_items: {
			from: 'extra_items',
			default: () => [],
		},
        addExtraItem: {
            from: 'addExtraItem',
            required: true
        },
        deleteExtraItem: {
            from: 'deleteExtraItem',
            required: true
        },
    },
    computed: {
        extraItemKey(){
            return `${this.additional_service_id}__${this.athlete_id}`
        },
        extraItem(){
            let extra_item = this.extra_items.find(item => item.key == this.extraItemKey)
            if(!extra_item){
                extra_item = {
                    order_extra_item_id: null,
                    is_payed: false,
                    additional_service_id: this.additional_service_id,
                    quantity: 1,
                    athlete_id: this.athlete_id,
                    distance_id: null,
                    key: this.extraItemKey,
                }
            }

            return extra_item
        },
        checked(){
            return this.extra_items.some(item => item.key == this.extraItemKey)
        },
        athlete(){
            return this.ATHLETES.find(item => item.id == this.athlete_id)
        },
        ...mapGetters({
            ATHLETES: 'athletes/getAthletes'
        })
    },
    methods: {
        changeCheckbox(){
            if(!this.checked) {
                this.addExtraItem(this.extraItem)
                return
            }
            this.deleteExtraItem(this.extraItemKey)
        },
    },
    components: {
        Avatar,
    }
}
</script>

<style scoped>
    .athlete_checkboxes__header{
        background: #e9ebf1;
        border-radius: 5px;
    }

    .athlete_checkboxes__distancies{
        background: #f5f6fa;
        padding-top: 5px;
        margin-top: -5px;
    }

    .athlete_checkboxes__distancies__item{
        border-bottom: 1px solid #E9EBF1;
    }

    .athlete_checkboxes__distancies__item:last-child{
        border: none;
    }

    .athlete_checkboxes__header .form-check-input:checked{
        background-color: #071031;
        border-color: #071031;
    }

    .athlete_checkboxes__distancies .form-check-input:checked{
        background-color: #28C76F;
        border-color: #28C76F;
    }

    .slide-enter-active,
    .slide-leave-active {
    transition: max-height 0.2s ease-in-out;
    }

    .slide-enter-to,
    .slide-leave-from {
    overflow: hidden;
    max-height: 1000px;
    }

    .slide-enter-from,
    .slide-leave-to {
    overflow: hidden;
    max-height: 0;
    }
</style>
