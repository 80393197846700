<template>
    <div class="notifications_link header_navbar__item nav-dropdown me-4">
        <CDropdown>
            <CDropdownToggle @click="setViewed" class="btn-circle">
                <vue-feather type="bell"></vue-feather>
                <span v-if="unreadNotifications" class="badge">{{
                    unreadNotifications
                }}</span>
            </CDropdownToggle>
            <CDropdownMenu>
                <CDropdownHeader v-if="notifications.length > 0"
                    :class="'d-flex justify-content-between align-items-center w-100 py-3'"
                >
                    <p class="text-muted mb-0">Уведомления</p>
                    <a
                        @click="deleteNotifications"
                        href="#"
                        class="text-muted text-decoration-underline clear-link"
                        >Очистить</a
                    >
                </CDropdownHeader>
                <CDropdownHeader v-else :class="'py-3'">
                    <p class="text-muted mb-0">Новых уведомлений нет</p>
                </CDropdownHeader>
                <template v-if="notifications.length">
                <CDropdownItem
                    v-for="(item, i) in notifications"
                    :key="i"
                    class="small"
                >
                    <router-link :to="'/request/event_form' + item.model_id">
                        <span v-html="item.text"></span>
                    </router-link>
                </CDropdownItem>
                </template>
            </CDropdownMenu>
        </CDropdown>
    </div>
</template>

<script>
import {
    CDropdown,
    CDropdownToggle,
    CDropdownMenu,
    CDropdownItem,
    CDropdownHeader,
} from "@coreui/vue";
import { mapGetters } from "vuex";
export default {
    components: {
        CDropdown,
        CDropdownToggle,
        CDropdownMenu,
        CDropdownItem,
        CDropdownHeader,
    },
    methods: {
        pollData() {
            setInterval(() => {
                this.$store.dispatch("profile/fetchNotifications");
                console.log("Запрос отправлен");
            }, 900000);
        },
        setViewed() {
            this.$store.dispatch("profile/fetchNotificationsSetViewed");
        },
        deleteNotifications() {
            this.$store.dispatch("profile/fetchNotificationsDelete");
        },
    },
    created() {
        this.pollData();
    },
    computed: {
        ...mapGetters({
            notifications: "profile/getNotifications",
        }),
        unreadNotifications() {
            if(this.notifications && this.notifications.length > 0){
                return this.notifications.filter((obj) => {
                    if (!obj.is_viewed) return true;
                }).length;
            }
            return null
        },
    },
};
</script>

<style scoped>
button:after {
    display: none;
}
a {
    text-decoration: none;
    color: #071031;
}
.clear-link {
    transition: all 0.4s;
}

.clear-link:hover {
    color: #071031 !important;
}
</style>
