<template>
<OffcanvasInline @hide="$parent.modal_visible = false" :visible="visible">
<p class="section_title_medium mb-3">{{item.name}}</p>
<p class="text-muted mb-3">{{item.description}}</p>

<p class="fw-bold mb-1">Выберите количество:</p>
<div class="input-group input-group-num mb-3">
    <button @click.prevent="minus" :disabled="!hasExtraItem" type="button" :class="!hasExtraItem ? '' : 'text-body'">-</button>
    <input :value="hasExtraItem ? extraItem.quantity : 0" type="number" class="form-control" min="0" step="1" onkeydown="return false" >
    <button @click.prevent="plus" type="button" class="text-body">+</button>
</div>

<button @click="$emit('hide')" class="btn btn-primary btn-primary-shadow btn-rounded mt-auto px-5">Сохранить</button>

</OffcanvasInline>
</template>

<script>
import OffcanvasInline from './OffcanvasInline.vue'
export default {
    props: {
        visible: {
            type: Boolean
        },
        item: {
            type: Object,
            default: {}
        },
    },
    inject: {
        extra_items: {
			from: 'extra_items',
			default: () => [],
		},
        addExtraItem: {
            from: 'addExtraItem',
            required: true
        },
        deleteExtraItem: {
            from: 'deleteExtraItem',
            required: true
        },
        changeExtraItemQuantity: {
            from: 'changeExtraItemQuantity',
            required: true
        },
    },
    computed: {
        extraItemKey(){
            return `${this.item.id}`
        },
        extraItem(){
            let extra_item = this.extra_items.find(item => item.key == this.extraItemKey)
            if(!extra_item){
                extra_item = {
                    order_extra_item_id: null,
                    is_payed: false,
                    additional_service_id: this.item.id,
                    quantity: 1,
                    athlete_id: null,
                    distance_id: null,
                    key: this.extraItemKey,
                }
            }

            return extra_item
        },
        hasExtraItem(){
            return this.extra_items.some(item => item.key == this.extraItemKey)
        },
    },
    methods: {
        plus(){
            if(!this.hasExtraItem) {
                this.addExtraItem(this.extraItem)
                return
            }
            this.changeExtraItemQuantity(this.extraItemKey, this.extraItem.quantity + 1)
        },
        minus(){
            if(!this.hasExtraItem) return
            if(this.extraItem.quantity > 1){
                this.changeExtraItemQuantity(this.extraItemKey, this.extraItem.quantity - 1)
                return
            }
            this.deleteExtraItem(this.extraItemKey)
        },
    },
    components: {
        OffcanvasInline
    },
}
</script>

<style>
</style>
