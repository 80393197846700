<template>
    <div class="expand_cont p-3 border rounded mb-2">
        <div v-if="athlete">
            <Avatar :src="athlete?.avatar" :name="athlete?.name" :class="'me-2'"/>
            <span class="fw-bold">{{athlete.name}}</span>
        </div>
        <div v-if="distances.length" class="mt-3">
            <p class="text-muted mb-0">Для дистанций</p>
            <p v-for="(distance, i) in distances" :key="i" class="mb-0">
                {{distance.distance_name}}
            </p>
        </div>
    </div>
</template>

<script>
import Avatar from '../UI/Avatar'
import { mapGetters } from "vuex"
export default {
    props: {
        items: {
            type: Array,
            required: true
        }
    },
    computed: {
        athlete(){
            return this.ATHLETES.find(item => item.id == this.items[0]?.athlete_id)
        },
        distances(){
            return this.items.map(item => {
                if(!item.distance_id) return
                return this.DISTANCES.find(distance => distance.id == item.distance_id)
            }).filter(item => !!item)
        },
        ...mapGetters({
            ATHLETES: 'athletes/getAthletes',
            DISTANCES: 'events/DISTANCES'
		}),
    },
    methods: {
        async setDistanceNames(){
            this.item.distances.forEach(id => {
                let distance = this.getDistance(id)
                if(distance) this.distances.push(distance)
            })
        }
    },
    components: { Avatar },
}
</script>

<style scoped>
    .expand_cont:last-child{
        margin-bottom: 0 !important;
    }
</style>