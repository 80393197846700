<template>
    <span v-if="is_payed" class="badge bg-success text-uppercase" style="font-family: 'Oswald', sans-serif;">Оплачен</span>
    <template v-else>
        <template v-if="status == 'reserved' || status == 'distance_full'">
            <span v-if="status == 'reserved'" class="badge bg-primary text-uppercase" style="font-family: 'Oswald', sans-serif;">Резерв</span>
            <span v-if="status == 'distance_full'" class="badge bg-danger text-uppercase" style="font-family: 'Oswald', sans-serif;">Нет мест</span>
        </template>
        <span v-else class="badge bg-secondary text-uppercase" style="font-family: 'Oswald', sans-serif;">Нет резерва</span>
    </template>
</template>

<script>
    export default {
        props: {
            status: {
                type: String,
                default: 'not_reserved'
            },
            is_payed: {
                type: Boolean,
                default: false
            },
        }
    }
</script>