<template>
<OffcanvasInline @hide="$parent.modal_visible = false" :visible="visible">
<p class="section_title_medium mb-3">{{item.name}}</p>
<p class="text-muted mb-3">{{item.description}}</p>

<p class="fw-bold mb-1">Отметьте спортсменов, для которых нужно получить услугу:</p>

<div class="athletes_checkboxes mb-3">
    <UpsellAthlete v-for="athlete_id in athletes" :key="athlete_id" :additional_service_id="item.id" :athlete_id="athlete_id"/>
</div>
<button @click.prevent="$parent.modal_visible = false" class="btn btn-primary btn-primary-shadow btn-rounded mt-auto px-5">Сохранить</button>
</OffcanvasInline>
</template>

<script>
import UpsellAthlete from '../forms/upsell/UpsellAthlete.vue'
import OffcanvasInline from './OffcanvasInline.vue'
import {mapGetters} from 'vuex'
export default {
    props: {
        visible: {
            type: Boolean
        },
        item: {
            type: Object,
            default: {}
        },
    },
    computed: {
        currentOrderItems(){
            return this.DISTANCE_ITEMS.filter(
				(item) => item.is_payed || item.reservation_status == 'reserved'
			)
        },
        athletes(){
            return [...new Set(this.currentOrderItems.map(item => item.athlete_id))]
        },
        ...mapGetters({
            DISTANCE_ITEMS: 'order/DISTANCE_ITEMS'
        }),
    },
    components: {
        OffcanvasInline,
        UpsellAthlete
    },
}
</script>
