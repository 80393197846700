<template>
    <default-layout>
        <template #content>
            <page-title title="Рекорды" :multiple_titles="[
                    {
                        title: 'Рейтинги',
                        route_name: 'rating'
                    },
                    {
                        title: 'Рекорды',
                        route_name: 'records'
                    },
                    {
                        title: 'Турнирные таблицы',
                        route_name: 'tournaments'
                    },
                    {
                        title: 'Архив результатов',
                        route_name: 'results'
                    },
                ]" />
            <div class="container mb-5">
                <FilterWrap>
                    <FilterSelect 
                        label="Мероприятие/Competition" 
                        v-model="filter.event_name" 
                        :className="'col-12 col-md-4 col-lg-3'">
                        <!-- <option value="0">Все</option> -->
                        <option v-for="(item, i) in filter_data.event_names" :key="i" :value="item.id">{{item.name}}</option>
                    </FilterSelect>
                    <FilterSelect 
                        label="Бассейн/Pool" 
                        v-model="filter.course" 
                        :className="'col-12 col-md-4 col-lg-2'">
                        <option value="0">Все</option>
                        <option v-for="(item, i) in filter_data.courses" :key="i" :value="item">{{courseName(item)}}</option>
                    </FilterSelect>
                    <FilterSelect 
                        label="Пол/Gender" 
                        v-model="filter.gender" 
                        :className="'col-12 col-md-4 col-lg-2'">
                        <option value="X">Все</option>
                        <option value="M">Юноши</option>
                        <option value="F">Девушки</option>
                    </FilterSelect>
                    <FilterSelect 
                        label="Возраст/Age" 
                        v-model="filter.athlete_age" 
                        :className="'col-12 col-md-4 col-lg-2'">
                        <option value="0">Все</option>
                        <option v-for="(item, i) in filter_data.athlete_age" :key="i" :value="item">{{athleteAgeName(item)}}</option>
                    </FilterSelect>
                    <FilterClear @clicked="clearFilter()"/>
                    <div class="col-auto d-flex align-items-end">
                        <button @click="fetchResults()" class="btn btn-sm btn-rounded btn-warning btn-shadow w-100 text-uppercase mb-1 px-4">Показать</button>
                    </div>
                </FilterWrap>
            </div>
            <div class="page_section" :style="loading ? 'opacity: .3;' : ''">
                <div class="container">
                    <template v-for="(cat, cat_name) in records" :key="cat_name">
                        <div class="record_category mb-5">
                            <h2 class="section_title_medium">{{cat_name}}</h2>
                            <div class="row g-4">
                                <template v-for="(subcat, subcat_name) in cat" :key="subcat_name">
                                    <template v-for="(item, i) in subcat" :key="i">
                                        <div class="col-md-6 col-lg-4 col-xl-3">
                                            <RecordCard :item="item" :name="subcat_name" has_link />
                                        </div>
                                    </template>
                                </template>
                            </div>
                        </div>
                    </template>
                    <p v-if="!isEmptyRecords" class="small text-muted mt-5">*Возраст участников соревнований определяется на 31 декабря года проведения соревнований.<br />**Выписка из Правил проведения соревнований по плаванию, утвержденных Всероссийской федерацией плавания (п.п. 2.1.1).</p>
                </div>
                <PageAlertBox v-if="isEmptyRecords && !loading" :alert="{class: 'alert-warning', title: 'Записей по вашему запросу не найдено', text: 'Пожалуйста, попробуйте изменить критерии фильтрации'}"/>
            </div>
        </template>
    </default-layout>
    </template>
    
    <script>
    import PageAlertBox from '../components/PageAlertBox.vue'
    import FilterClear from '../components/filter/FilterClear.vue'
    import FilterSelect from '../components/filter/FilterSelect.vue'
    import FilterWrap from '../components/filter/FilterWrap'
    import PageTitle from '../components/PageTitle.vue'
    import DefaultLayout from '@/layouts/DefaultLayout.vue'
    import FilterBlue from '@/components/FilterBlue.vue'
    import RecordCard from '@/components/cards/RecordCard.vue'
    import setMeta from "@/views/seoMeta"
    import {strokes} from '@/helpers/fieldNames'
    import axios from "axios"
    export default {
        data() {
            return {
                records: [],
                filter: {
                    /* course: 0,
                    gender: 'X',
                    athlete_age: 0,
                    event_name: 0, */
                    course: 'LCM',
                    gender: 'M',
                    athlete_age: 0,
                    event_name: 5,
                },
                filter_data: {
                    event_names: [],
                    courses: ['SCM', 'LCM'],
                    athlete_age: [9,10,11,12,13,14,15,16,17,18,'19+'],
                },
                filter_age: {
                    male: [
                        9,10,11,12,13,14,15,16,17,18,'19+'
                    ],
                    female: [
                        9,10,11,12,13,14,15,16,17,'18+'
                    ]
                },
                loading: false
            }
        },
        async mounted() {
            await this.fetchResults()
        },
        watch: {
            'filter.gender': {
                handler(val){
                    this.filter.athlete_age = 0
                    this.filter_data.athlete_age = val == 'M' ? this.filter_age.male : this.filter_age.female
                }
            }
        },
        methods: {
            clearFilter(){
                this.filter = {
                    event_name: 0,
                    course: 0,
                    gender: 'X',
                    athlete_age: 0,
                }
                this.fetchResults()
            },
            async fetchResults(){
                this.loading = true;
                try{
                    const response = await axios.get(process.env.VUE_APP_BASE_URL+'results/records', {params: this.filter})
                    this.records = response.data.results
                    this.records = this.sortRecords
                    this.filter_data.event_names = response.data.filters.event_names
                    this.loading = false;
                } catch (e){
                    console.log(e);
                    this.loading = false;
                    if(e.response.status === 404){
                        router.push({name: 'not_found'})
                    } else {
                        alert("Ошибка получения данных с сервера о рекордах")
                    }
                }
            },
            courseName(course){
                if(course == 'SCM') return '25 метров'
                if(course == 'LCM') return '50 метров'
            },
            order(unordered) {
                return Object.keys(unordered).sort((a, b) => {
                    if(strokes.indexOf(a) < 0){
                        return strokes.indexOf(b) - strokes.indexOf(a)
                    }
                    return strokes.indexOf(a) - strokes.indexOf(b)
                }).reduce(
                    (obj, key) => {
                        obj[key] = unordered[key];
                        return obj;
                    }, {}
                )
            },
            athleteAgeName(age){
                if(typeof age == 'string' && age.includes('+')){
                    return age.replace('+', '') + ' лет и старше'
                }
                 return age + ' лет'
            }
        },
        computed: {
            sortRecords(){
                let ordered = this.order(this.records)
                for (let [key, value] of Object.entries(ordered)) {
                    let deep_ordered = Object.keys(value).sort((a, b) => {
                        parseInt(a.split(' - ')[0])
                        return (parseInt(a.split(' - ')[0]) > parseInt(b.split(' - ')[0])) ? 1 : -1
                    }).reduce(
                        (obj, key) => {
                            obj[key] = value[key];
                            return obj;
                        }, {}
                    )
                    ordered[key] = deep_ordered
                }
                
                return ordered
            },
            isEmptyRecords(){
                return _.isEmpty(this.records)
            },
        },
        metaInfo() {
            return setMeta(
                'Рекорды | Swim4you.ru', 
                null, 
                null,
                false
            )
        },
        components: {
            FilterWrap,
            DefaultLayout,
            FilterBlue,
            PageTitle,
            RecordCard, FilterSelect, FilterClear, PageAlertBox
        },
    }
    </script>
    
    <style>
    </style>
    