<template>
	<div class="col-xxl-6">
		<div class="input_add_edit row gx-1">
			<div class="col-auto">
				<label for="add_edit">
					<span class="fw-bold"
						>Выберите участника <span class="text-danger">*</span></span
					>
				</label>
			</div>
			<div v-if="!hasDistances" class="col text-end">
				<a
					v-if="!searchInDatabase"
					@click.prevent=";(searchInDatabase = !searchInDatabase), clearData()"
					href="#"
					class="text-muted small"
					>Выбрать из базы</a
				>
				<a
					v-if="searchInDatabase"
					@click.prevent=";(searchInDatabase = !searchInDatabase), clearData()"
					href="#"
					class="text-muted small"
					>Выбрать из аккаунта</a
				>
			</div>
			<div class="col-12">
				<div class="row g-2">
					<div class="col-11">
                        <VSelect
							v-if="!searchInDatabase"
							v-model="theModel"
							:options="approvedUserAthletes"
                            label="athlete_name_birthday"
							:selectable="(option) => !!option.approve"
							:reduce="(option) => option.id"
                            :disabled="hasDistances"
							@option:selected="selectAthlete"
							placeholder="Выберите участника"
							:class="show_errors && !theModel ? 'invalid' : ''">
                            <template v-slot:option="option">
                                {{ option.athlete_name_birthday }}<br>
                                <span v-if="option.approve_reason" class="small">{{ option.approve_reason }}</span>
                            </template>
							<template #no-options>
								<div>Нет подходящих вариантов</div>
							</template>
						</VSelect>

						<VSelect
							v-if="searchInDatabase"
							v-model="theModel"
							:options="finded_athletes"
                            label="name"
							:selectable="(option) => !!option.approve"
                            :disabled="hasDistances"
							:reduce="(option) => option.id"
                            @search="onSearch"
							@option:selected="selectAthlete"
							:dropdown-should-open="showOptionsList"
							placeholder="Начните вводить фамилию"
							:class="show_errors && !theModel ? 'invalid' : ''">
                            <template v-slot:option="option">
                                {{ option.name }} {{ option.birthdate }}<br>
                                <span v-if="option.approve_reason" class="small">{{ option.approve_reason }}</span>
                            </template>
							<template #no-options>
								<div>Нет подходящих вариантов</div>
							</template>
						</VSelect>
						<p v-if="show_errors && !theModel" class="mb-0 small text-danger">
							Обязательное поле
						</p>
					</div>
					<div v-if="!hasDistances" class="col-1" style="padding: 5px 0 5px 0;"><button @click.prevent="visible_modals.create_athlete = true" class="btn btn-secondary w-100 h-100 fs-4 py-0 px-2">+</button></div>
				</div>
			</div>
			<div class="col-12">
				<a
					v-if="!searchInDatabase && theModel"
					@click.prevent="visible_modals.edit_athlete = true"
					href="#"
					class="text-muted small"
					>Редактировать выбранного</a
				>
			</div>
		</div>
		<EditAthlet
			:visible="visible_modals.edit_athlete"
			:athlete_data="athlete"
			@hide="visible_modals.edit_athlete = false" />
		<EditAthlet
			:visible="visible_modals.create_athlete"
			@hide="visible_modals.create_athlete = false" />
	</div>
</template>

<script>
import EditAthlet from '../offcanvases/EditAthlet.vue'
import VSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'
import { computed } from 'vue'
import { mapGetters, mapActions } from 'vuex'
export default {
	props: {
		modelValue: {
			type: Number,
			default: null,
		},
		athlete: {
			type: Object,
			default: null
		},
        hasDistances: {
            type: Boolean,
            default: false
        }
	},
    inject: {
        show_errors: {
            from: 'show_errors',
            default: false
        },
        used_athletes: {
            from: 'used_athletes',
            default: () => ([]),
        },
        disallowed_athletes: {
            from: 'disallowed_athletes',
            default: () => ([]),
        },
        age_limits: {
            from: 'age_limits',
            default: () => ([]),
        },
        localdisallowed_athletes: {
            from: 'localdisallowed_athletes',
            default: () => ([]),
        }
    },
	data() {
		return {
			searchInDatabase: false,
			visible_modals: {
				edit_athlete: false,
				create_athlete: false,
			},
            finded_athletes: [],
		}
	},
	computed: {
        approvedUserAthletes(){
            return this.setAthletesApproved(this.ATHLETES).sort((a, b) => b.approve - a.approve)
        },
		...mapGetters({
			ATHLETES: 'profile/getAthletes',
		}),
	},
	
	methods: {
        setAthletesApproved(array){
            if(!Array.isArray(array)) return [];
            return array.map((item) => {
                item.approve = true
                item.approve_reason = null

                if(this.used_athletes.includes(item.id)){
                    item.approve = false
                    item.approve_reason = 'Участник уже добавлен'
                }
                else if(this.disallowed_athletes.includes(item.id)){
                    item.approve = false
                    item.approve_reason = 'Участник уже заявлен'
                }
                else if(!this.getAthleteAgelimit(item)){
                    item.approve = false
                    item.approve_reason = 'Участник не подходит к этому событию'
                }
                else if(!this.hasAgeLimitPlaces(item) || this.localdisallowed_athletes.includes(item.id)){
                    item.approve = false
                    item.approve_reason = 'Возрастной лимит заполнен'
                }

                return item
            })
        },
		
		async onSearch(search, loading) {
			if (search.length && search.length > 3) {
				loading(true)
                let searched_athletes = await this.searchAthletes(search)
				this.finded_athletes = this.setAthletesApproved(searched_athletes)
				loading(false)
			}
		},
		selectAthlete(athlete) {
            if(this.searchInDatabase){
                this.$store.commit('athletes/changeAthlete', athlete)
            }
			this.$emit('selectAthlete', athlete)
		},
		clearData() {
			this.theModel = null
			this.$emit('selectAthlete', null)
		},
		showOptionsList(VueSelect) {
			if (this.theModel !== null) {
				return VueSelect.open
			}
			return VueSelect.search.length !== 0 && VueSelect.open
		},
		checkAthleteFromDatabase() {
			if (this.theModel) {
				let exist = this.ATHLETES.find((item) => {
					return item.id == this.theModel
				})
				if (!exist) this.searchInDatabase = true
			}
		},
        getAthleteAgelimit(athlete){
			let current_age_limit = this.age_limits.find((item) => {
				let athlete_year = athlete.birthdate.split('/').pop()
				if (athlete.gender === 'M') {
					return item.male_years.includes(athlete_year)
				} else if (athlete.gender === 'F') {
					return item.female_years.includes(athlete_year)
				}
				return item.gender === athlete.gender && item.years.includes(athlete_year)
			})
            return current_age_limit ?? null
        },
		hasAgeLimitPlaces(athlete) {
			let current_age_limit = this.getAthleteAgelimit(athlete)
			return current_age_limit?.has_vacant_places ?? false
		},
        checkAthleteDistallowed(athlete_id){
            if(this.disallowed_athletes && this.disallowed_athletes.includes(athlete_id)){
                this.$swal(
					'Этот спортсмен уже заявлен',
					'Если вы сами подавали заявку с этим спортсменом для этого мероприятия, вы можете отредактировать эту заявку в разделе "Мои заявки" вашего аккаунта',
					'error'
				)
                return false
            }
            return true
        },
        ...mapActions({
			searchAthletes: 'athletes/searchAthletes',
		}),
	},
    watch: {
		theModel: {
			handler(newVal, oldVal) {
                if(!this.checkAthleteDistallowed(newVal)) {
                    this.theModel = oldVal
                    return
                }
				this.checkAthleteFromDatabase()
			},
			deep: true,
		},
	},
	setup(props, { emit }) {
		const theModel = computed({
			get: () => props.modelValue,
			set: (value) => emit('update:modelValue', value),
		})
		return { theModel }
	},
	emits: ['update:modelValue', 'selectAthlete'],
	components: { VSelect, EditAthlet },
}
</script>

<style></style>
