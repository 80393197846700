<template>
<div class="content_box p-4 mb-4">
    <div class="content_box__header d-flex justify-content-between align-items-center mb-3 position-relative z-2">
        <h2 class="title_block mb-2">Выберите эстафеты, в которых участвует клуб</h2>
    </div>
    <div class="relay_form">
        <EstafetaField 
            v-for="item in items"
            :key="item.id" 
            :item="item" />
    </div>
</div>
</template>

<script>
import EstafetaField from '@/components/forms/EstafetaField.vue'
export default {
    components: { EstafetaField },
    props: {
        items:{
            type: Array,
            default: () => []
        },
        price: {
            type: Number,
            default: 0
        }
    },
}
</script>

<style>

</style>
