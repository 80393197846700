<template>
	<with-sidebar-layout>
		<template #head>
			<page-title title="Форма заявки" :breadcrumbs="breadcrumbs" />
			<PageAlertBox
				v-if="postData.alert_message"
				:alert="{
					class: 'alert-warning',
					title: 'Внимание!',
					text: postData.alert_message,
				}" />
			<PageAlertBox
				v-if="hasErrors && show_errors"
				:alert="{
					class: 'alert-danger',
					title: 'Форма содержит ошибки',
					text: 'Пожалуйста, проверьте правильность заполнения полей',
				}" />
		</template>
		<template #content>
			<FormSteps :steps="steps" :activeStep="activeStep" />

			<div class="form_step form_step_1" ref="form_step_1" v-if="activeStep === 'distances'">
				<FormClubFieldgroup
					v-model:club_name="form.club_name"
					v-model:club_shortname="form.club_shortname"
					v-model:club_state="form.club_state"
					v-model:club_city="form.club_city"
					v-model:has_errors="club_has_errors"
					@selectClub="selectClub" />

				<FormContactsFieldgroup
					v-model:agent_name="form.agent_name"
					v-model:agent_phone="form.agent_phone"
					v-model:agent_email="form.agent_email"
					v-model:has_errors="contacts_has_errors" />

				<FormAthletesSet
					v-if="form.club_name"
					v-model="form_elements.items"
					v-model:has_errors="distances_has_errors"
					title="Добавьте спортсменов и дистанции"
					subtitle="Добавьте необходимое кол-во спортсменов, документы для подачи онлайн-заявки, а также дистанции и заявочное время. В случае, если в базе данных имеется информация о результатах в выбранной дистанции, она проставится автоматически" />
			</div>

			<div
				v-if="postData.relay_races && postData.relay_races.length && activeStep === 'relay_races'"
				class="form_step">
				<FormRelayRacesSet
					:items="postData.relay_races"
					:price="postData.relay_price" 
                />
			</div>

			<div
				v-if="
					postData.extra_services && postData.extra_services.length && activeStep === 'extra_items'
				"
				class="form_step">
				<FormExtraServicesSet
					:items="postData.extra_services"
					:distances="form_elements.items" />
			</div>

			<div class="form_step" v-if="activeStep === 'confirm'">
				<FormCompetitionLastStep
					v-model:payers_name="form.payers_name"
					v-model:payers_phone="form.payers_phone"
					v-model:payers_email="form.payers_email"
					v-model:payment_method="form.payment_method"
					v-model:has_errors="last_step_errors"
                    :hide_payment_methods="false"
					@sendOrder="storeFormConfirm" />
			</div>

				<FormAcceptChecks
                    v-if="activeStep == lastStep && this.postData?.accept_checkboxes && this.postData.accept_checkboxes.length"
					v-model:accept_checks="accept_checks"
					:show_errors="show_errors"
					:has_errors="hasErrors"
				/>
		</template>

		<template #sidebar>
			<div class="sticky_box">
				<ExpiredBox
					v-if="expired_time"
					:time="expired_time"
					:bottom_text="
						orderdata.already_paid
							? 'В противном случае изменения не будут сохранены'
							: null
					"
					@time_end="setExpire" />

				<InstructionsBox
					:button_disabled="false"
					:button_hide="!!orderdata.already_paid"
					button_text="Сохранить как черновик"
					@saveAsDraft="storeFormDraft" />
			</div>
		</template>

		<template #bottom_bar>
			<div class="bottom_form_navbar py-2">
				<div class="container">
					<div class="row g-3 align-items-center">
                        <div v-if="activeStep == 'distances' && hasUnreservedDistances" class="col-12 d-md-none pt-3">
							<div class="reservation_notice fw-bold">
                                Для перехода к следующему шагу необходимо зарезервировать дистанции
                            </div>
						</div>
						<div class="col-6 col-md-auto" v-if="activeStep != 'distances'">
							<button
								@click.prevent="prevStep"
								class="btn btn-outline btn-outline-secondary btn-rounded btn_big_icon px-5">
                                <vue-feather type="arrow-left-circle" class="me-2 ms-0" size="32" stroke-width="1" />
								Назад
							</button>
						</div>
						<div class="col-6 col-md-auto" v-if="activeStep == 'distances'">
							<button
								@click.prevent="storeFormReserve"
								class="btn btn-primary btn-rounded"
								:disabled="!form.club_name || !hasUnreservedDistances">
								Резерв дистанций
							</button>
						</div>
						<div v-if="activeStep == 'distances' && hasUnreservedDistances" class="col-md-4 d-none d-md-block">
							<div class="reservation_notice fw-bold">
                                Для перехода к следующему шагу необходимо зарезервировать дистанции
                            </div>
						</div>
						<div class="col-6 col-md-auto ms-auto">
							<button
                                v-if="activeStep != lastStep"
                                :disabled="!hasReservedDistances"
								@click.prevent="nextStep"
								class="btn btn-outline btn-outline-secondary btn-rounded btn_big_icon px-5 ms-auto">
								Далее
                                <vue-feather type="arrow-right-circle" class="ms-2 me-0" size="32" stroke-width="1" />
							</button>
                            
                            <button v-else @click.prevent="storeFormConfirm" :disabled="show_errors && (hasErrors || hasLastErrors)" class="btn btn-primary btn-primary-shadow btn-rounded px-5">{{sendBtnText}}</button>
						</div>
					</div>
				</div>
			</div>
		</template>
	</with-sidebar-layout>

	<ShopOffcanvas
		v-if="postData.shop_products && postData.shop_products.length"
		:item="{
			title: postData.shop_title,
			subtitle: postData.shop_subtitle,
			items: postData.shop_products,
			event_stage_id: postData.id,
		}"
		:visible="shop_modal_visible"
		@hide="shop_modal_visible = false" />
</template>

<script>
import ShopOffcanvas from '../../components/offcanvases/ShopOffcanvas.vue'
import ExpiredBox from '../../components/forms/ExpiredBox'
import FormAcceptChecks from './FormAcceptChecks'
import FormExtraServicesSet from './FormExtraServicesSet'
import FormRelayRacesSet from './FormRelayRacesSet'
import FormAthletesSet from './FormAthletesSet'
import FormContactsFieldgroup from './FormContactsFieldgroup'
import FormClubFieldgroup from './FormClubFieldgroup'
import FormCompetitionLastStep from './FormCompetitionLastStep'
import FormSteps from '@/components/forms//FormSteps'
import PageAlertBox from '../../components/PageAlertBox.vue'
import PageTitle from '../../components/PageTitle.vue'
import InstructionsBox from '../../components/forms/InstructionsBox.vue'
import WithSidebarLayout from '@/layouts/WithSidebarLayout.vue'
import { useToast } from 'vue-toastification'
import { mapActions, mapGetters } from 'vuex'
import { computed } from 'vue'
import { itemTemplate, extraItemTemplate, relayItemTemplate } from '@/helpers/formTemplates'
export default {
	props: {
		postData: {
			type: Object,
			default: {},
		},
		orderdata: {
			type: Object,
			default: {},
		},
	},
	data() {
		return {
			form: {
				order_id: null,
				type: 'competition',
				event_id: null,
				event_child_id: null,
				club_name: null,
				club_shortname: null,
				club_state: null,
				club_city: null,
				agent_name: null,
				agent_phone: null,
				agent_email: null,
				payers_name: null,
				payers_phone: null,
				payers_email: null,
				payment_method: null,
			},
			form_elements: {
				items: [],
				relay_items: [],
				extra_items: [],
			},
			summ: 0,
			expired_time: null,
			activeStep: 'distances',
			club_has_errors: false,
			contacts_has_errors: false,
			distances_has_errors: false,
			last_step_errors: false,
			accept_checks: [],
			show_errors: false,
			shop_modal_visible: false,
		}
	},
	async mounted() {
		if (this.postData && !_.isEmpty(this.postData)) {
			this.setFormFromPost()
		}
		if (this.orderdata && !_.isEmpty(this.orderdata)) {
			this.setOrderData(this.orderdata)
			//this.transformOrderData()
			//this.addDistancesPrice()
		} else {
			this.setDefaultForm()
		}
	},
	computed: {
        hasReservedDistances(){
            return !!this.form_elements.items.find((item) => {
                return !!item.distances.find(distance => distance.is_payed || distance.reservation_status == 'reserved')
            })
        },
        hasUnreservedDistances(){
            return !!this.form_elements.items.find((item) => {
                return !!item.distances.find(distance => distance.id && !distance.is_payed && distance.reservation_status != 'reserved')
            })
        },
		hasDistances() {
			return !!this.form_elements.items.find((item) => {
				return !!item.distances.find((el) => !!el.id)
            })
		},
		breadcrumbs() {
			return [
				{
					title: this.postData.name,
					link: '/stage/' + this.postData.id,
				},
			]
		},
		steps() {
			let steps = [
				{
					id: 'distances',
					name: 'Формирование заявки',
				},
			]
            
			if (this.postData.relay_races && this.postData.relay_races.length) {
				steps.push({
					id: 'relay_races',
					name: 'Участие в эстафетах',
				})
			}
			if (this.postData.extra_services && this.postData.extra_services.length) {
				steps.push({
					id: 'extra_items',
					name: 'Дополнительные услуги',
				})
			}

            steps.push({
                id: 'confirm',
                name: 'Подтверждение',
            })

            return steps
		},
		lastStep() {
			return this.steps[this.steps.length - 1].id
		},
		sendBtnText(){
            if(this.postData.first_status != 'pending_payment') return 'Отправить'
            if(this.extraPay == null) return 'Перейти к оплате'
            if(this.extraPay <= 0) return 'Отправить'
            return 'Перейти к оплате'
        },
		extraPay() {
			if (!this.orderdata || !this.orderdata.already_paid) return null
			return this.orderdata.summ - this.orderdata.already_paid
		},
        hasErrors() {
			if (this.club_has_errors || this.contacts_has_errors || this.distances_has_errors) {
				return true
			} else {
				return false
			}
		},
		hasLastErrors() {
			if (
				this.last_step_errors ||
				(this.accept_checks.length && this.accept_checks.find((item) => item.check == false))
			) {
				return true
			} else {
				return false
			}
		},
        ...mapGetters({
			USERDATA: 'profile/getUserData',
			EXTRA_ITEMS: 'order/EXTRA_ITEMS',
			RELAY_ITEMS: 'order/RELAY_ITEMS',
		}),
	},
	provide() {
		return {
			show_errors: computed(() => this.show_errors),
			required_documents: this.postData?.athlete_documents,
			disallowed_athletes: this.postData?.athletes ?? [],
			age_limits: this.postData.age_limits,
			agedata_value: this.postData.agedata_value,
			event_stage_id: this.postData.id,
			offline_request_text: this.postData.offline_request_text,
			addExtraItem: this.addExtraItem,
			deleteExtraItem: this.deleteExtraItem,
			changeExtraItemQuantity: this.changeExtraItemQuantity,
			addRelayItem: this.addRelayItem,
			deleteRelayItem: this.deleteRelayItem,
			changeRelayItemQuantity: this.changeRelayItemQuantity,
			order_items: computed(() => this.form_elements.items),
			relay_items: computed(() => this.form_elements.relay_items),
			extra_items: computed(() => this.form_elements.extra_items),
		}
	},
	methods: {
		async storeFormDraft() {
			this.$swal({
				title: 'Сохранение заявки',
				text: 'Пожалуйста, не закрывайте страницу',
				icon: 'info',
				allowOutsideClick: false,
				allowEnterKey: false,
				allowEnterKey: false,
				showConfirmButton: false,
				didOpen: () => {
					this.$swal.showLoading()
				},
			})

			let response = await this.confirmForm({
                request: this.getFormData(), 
                action: 'draft'
            })
			if (response?.success && response?.order_id) {
				if (!this.form.order_id) {
					this.$router.replace({ name: 'order_edit', params: { id: response.order_id } })
					return
				}
				this.$swal.close()
                this.showToast('Заявка сохранена')
			} else {
				this.$swal(
					'Ошибка при сохранении заявки',
					response?.message ?? 'Пожалуйста, попробуйте еще раз',
					'error'
				)
			}
		},
		async storeFormReserve() {
			this.$swal({
				title: 'Резервирование дистанций',
				text: 'Пожалуйста, не закрывайте страницу',
				icon: 'info',
				allowOutsideClick: false,
				allowEnterKey: false,
				allowEnterKey: false,
				showConfirmButton: false,
				didOpen: () => {
					this.$swal.showLoading()
				},
			})

			let response = await this.storeForm({
                request: this.getFormData(), 
                action: 'reserve'
            })
			if (response?.success && response?.order) {
				if (!this.form.order_id) {
					this.$router.replace({ name: 'order_edit', params: { id: response?.order.id } })
					return
				}
				this.$swal.close()
			} else {
				this.$swal(
					'Ошибка при резервировании дистанций',
					response?.message ?? 'Пожалуйста, попробуйте еще раз',
					'error'
				)
			}
		},
		async storeFormRelays() {
            if(!this.form_elements.relay_items.length && !this.RELAY_ITEMS.length) return
            if(_.isEqual(this.form_elements.relay_items, this.RELAY_ITEMS)) return

            this.$swal({
				title: 'Сохранение эстафет',
				text: 'Пожалуйста, не закрывайте страницу',
				icon: 'info',
				allowOutsideClick: false,
				allowEnterKey: false,
				allowEnterKey: false,
				showConfirmButton: false,
				didOpen: () => {
					this.$swal.showLoading()
				},
			})

            let response = await this.storeRelayItems({
                relay_items: this.form_elements.relay_items, 
                order_id: this.form.order_id
            })
            
			if (response?.success) {
				this.$swal.close()
			} else {
				this.$swal(
					'Ошибка при сохранении эстафет',
					response?.message ?? 'Пожалуйста, попробуйте еще раз',
					'error'
				)
                throw new Error(response?.message);
			}
        },
		async storeFormExtra() {
            if(!this.form_elements.extra_items.length && !this.EXTRA_ITEMS.length) return
            if(_.isEqual(this.form_elements.extra_items, this.EXTRA_ITEMS)) return

            this.$swal({
				title: 'Сохранение дополнительных услуг',
				text: 'Пожалуйста, не закрывайте страницу',
				icon: 'info',
				allowOutsideClick: false,
				allowEnterKey: false,
				allowEnterKey: false,
				showConfirmButton: false,
				didOpen: () => {
					this.$swal.showLoading()
				},
			})

            let response = await this.storeExtraItems({
                extra_items: this.form_elements.extra_items, 
                order_id: this.form.order_id
            })
            
			if (response?.success) {
				this.$swal.close()
			} else {
				this.$swal(
					'Ошибка при сохранении дополнительных услуг',
					response?.message ?? 'Пожалуйста, попробуйте еще раз',
					'error'
				)
                throw new Error(response?.message);
			}
        },
		async storeFormConfirm() {
            if (this.hasErrors || this.hasLastErrors) {
				this.show_errors = true
				/* this.$nextTick(() => {
					this.$el.querySelector('.invalid').focus()
				}) */
                return
			}

            this.$swal({
				title: 'Отправка заявки',
				text: 'Пожалуйста, не закрывайте страницу',
				icon: 'info',
				allowOutsideClick: false,
				allowEnterKey: false,
				allowEnterKey: false,
				showConfirmButton: false,
				didOpen: () => {
					this.$swal.showLoading()
				},
			})

			let response = await this.confirmForm({
                order_id: this.form.order_id,
                payers_name: this.form.payers_name,
                payers_phone: this.form.payers_phone,
                payers_email: this.form.payers_email,
                payment_method: this.form.payment_method,
            })
			if (response?.success && response?.url) {
				window.location.href = url
			} else if(response?.success) {
                this.$swal.close()
                this.showToast('Заявка успешно отправлена')
                this.$router.push({name: 'order', params: { id: this.form.order_id}})
            } else {
				this.$swal(
					'Ошибка при отправке заявки',
					response?.message ?? 'Пожалуйста, попробуйте еще раз',
					'error'
				)
			}
        },
        addExtraItem(payload){
            if(!payload?.additional_service_id) {
                return
            }
            let extra_item = {...extraItemTemplate}
            if(payload?.order_extra_item_id) extra_item.order_extra_item_id = payload?.order_extra_item_id
            if(payload?.additional_service_id) extra_item.additional_service_id = payload?.additional_service_id
            if(payload?.quantity) extra_item.quantity = payload?.quantity
            if(payload?.athlete_id) extra_item.athlete_id = payload?.athlete_id
            if(payload?.distance_id) extra_item.distance_id = payload?.distance_id
            if(payload?.key){
                extra_item.key = payload?.key
            } else {
                extra_item.key = `${extra_item.additional_service_id}__${extra_item.athlete_id}__${extra_item.distance_id}`
            }
            
            const index = this.form_elements.extra_items.findIndex(item => item.key === extra_item.key)
            if(index !== -1){
                this.form_elements.extra_items[index] = extra_item
            } else {
                this.form_elements.extra_items.push(extra_item)
            }
        },
        deleteExtraItem(key){
            this.form_elements.extra_items = this.form_elements.extra_items.filter(item => item.key != key)
        },
        changeExtraItemQuantity(key, quantity){
            let index = this.form_elements.extra_items.findIndex(item => item.key == key)
            if(index === -1) return
            this.form_elements.extra_items[index].quantity = quantity
        },
        addRelayItem(payload){
            if(!payload?.distance_id) {
                return
            }
            let relay_item = {...relayItemTemplate}
            if(payload?.order_item_id) relay_item.order_item_id = payload?.order_item_id
            if(payload?.quantity) relay_item.quantity = payload?.quantity
            if(payload?.distance_id) relay_item.distance_id = payload?.distance_id
            if(payload?.key){
                relay_item.key = payload?.key
            } else {
                relay_item.key = `${relay_item.distance_id}`
            }
            
            const index = this.form_elements.relay_items.findIndex(item => item.key === relay_item.key)
            if(index !== -1){
                this.form_elements.relay_items[index] = relay_item
            } else {
                this.form_elements.relay_items.push(relay_item)
            }
        },
        deleteRelayItem(key){
            this.form_elements.relay_items = this.form_elements.relay_items.filter(item => item.key != key)
        },
        changeRelayItemQuantity(key, quantity){
            let index = this.form_elements.relay_items.findIndex(item => item.key == key)
            if(index === -1) return
            this.form_elements.relay_items[index].quantity = quantity
        },
		getFormData() {
			let formData = {
				...this.form,
				items: [],
				extra_items: [],
			}

			this.form_elements.items.forEach((item) => {
				item.distances.forEach((distance) => {
					distance = { ...distance }
					distance.distance_id = distance.id
					distance.id = distance.order_item_id
					delete distance.order_item_id

					formData.items.push({
						athlete_id: item.athlete_id,
						online_request: item.online_request,
						...distance,
					})
				})
			})

			return formData
		},
		setDefaultForm() {
			this.form_elements.items.push({ ...itemTemplate })
		},
		selectClub(selected_club) {
			if (selected_club?.agent_name) this.form.agent_name = selected_club.agent_name
			if (selected_club?.agent_phone) this.form.agent_phone = selected_club.agent_phone
			if (selected_club?.agent_email) this.form.agent_email = selected_club.agent_email
		},
		async setExpire() {
            await this.expireOrder(this.form.order_id)
			this.$swal(
				'Время бронирования истекло',
				'Неоплаченные дистанции сняты с резерва',
				'warning'
			)
            this.activeStep = 'distances'
		},
		setFormFromPost() {
			this.form.event_id = this.postData.event_id
			this.form.event_child_id = this.postData.id
			this.form.entry_fee = this.postData.entry_fee
			if (this.postData?.accept_checkboxes) {
				this.accept_checks = this.postData.accept_checkboxes.map((obj) => ({
					...obj,
					check: false,
				}))
			}
		},
		showToast(text) {
			const toast = useToast()
			toast.info(text)
		},
		prevStep() {
			let current_step_index = this.steps.findIndex((item) => item.id == this.activeStep)
			this.activeStep = this.steps[current_step_index - 1]?.id ?? this.steps[0].id
		},
		async nextStep() {
			if (this.hasErrors) {
				this.show_errors = true
				this.$nextTick(() => {
					//this.$el.querySelector('.invalid').focus()
				})
                return
			}
            if(this.activeStep == 'relay_races'){
                await this.storeFormRelays()
            } 
            if(this.activeStep == 'extra_items'){
                await this.storeFormExtra()
            } 
			let current_step_index = this.steps.findIndex((item) => item.id == this.activeStep)
			this.activeStep = this.steps[current_step_index + 1]?.id ?? this.steps[0].id
		},
		
		setOrderData(payload) {
			this.form_elements.items = []
			if (payload?.id) this.form.order_id = payload.id
			if (payload?.club_name) this.form.club_name = payload.club_name
			if (payload?.club_shortname) this.form.club_shortname = payload.club_shortname
			if (payload?.club_state) this.form.club_state = payload.club_state
			if (payload?.club_city) this.form.club_city = payload.club_city
			if (payload?.agent_name) this.form.agent_name = payload.agent_name
			if (payload?.agent_phone) this.form.agent_phone = payload.agent_phone
			if (payload?.agent_email) this.form.agent_email = payload.agent_email
			if (payload?.payers_name) this.form.payers_name = payload.payers_name
			if (payload?.payers_phone) this.form.payers_phone = payload.payers_phone
			if (payload?.payers_email) this.form.payers_email = payload.payers_email
			if (payload?.payment_method) this.form.payment_method = payload.payment_method
			if (payload?.summ) this.summ = payload.summ
			if (payload?.expiration_time || payload?.expiration_time === null) this.expired_time = payload.expiration_time

			if (payload?.order_items) {
				const itemsMap = new Map()
				payload.order_items
					.filter((item) => item.type == 'distance')
					.forEach((data) => {
						const {
							athlete_id,
							online_request,
							id,
							distance_id,
							entrytime,
							system_entrytime,
							is_payed,
							reservation_status,
							key,
						} = data

						if (!itemsMap.has(athlete_id)) {
							itemsMap.set(athlete_id, {
								athlete_id,
								online_request,
								distances: [],
							})
						}

						const item = itemsMap.get(athlete_id)

						item.distances.push({
							order_item_id: id,
							key,
							id: distance_id,
							entrytime,
							system_entrytime,
							is_payed,
							reservation_status,
						})
					})

				this.form_elements.items = Array.from(itemsMap.values())

				this.form_elements.relay_items = payload.order_items.filter(item => item.type == 'relay_race').map(item => {
                    return {
                        distance_id: item.distance_id,
                        key: item.key,
                        order_item_id: item.id,
                        quantity: item.quantity,
                    }
                })
			}

            if (payload?.extra_items) {
                this.form_elements.extra_items = [...payload.extra_items]
            }
		},

		...mapActions({
			storeForm: 'order/storeForm',
			confirmForm: 'order/confirmForm',
			expireOrder: 'order/expireOrder',
			storeExtraItems: 'order/storeExtraItems',
			storeRelayItems: 'order/storeRelayItems',
		}),
	},
	watch: {
		activeStep(val, oldVal) {
			if (val == 'confirm') {
				this.shop_modal_visible = true
			}
		},
        orderdata: {
            handler(newVal, oldVal){
                this.setOrderData(newVal)
            },
            deep: true
        }
	},
	components: {
		ExpiredBox,
		FormAcceptChecks,
		FormExtraServicesSet,
		FormRelayRacesSet,
		FormAthletesSet,
		FormContactsFieldgroup,
		FormClubFieldgroup,
		FormCompetitionLastStep,
		FormSteps,
		WithSidebarLayout,
		InstructionsBox,
		PageTitle,
		PageAlertBox,
		ShopOffcanvas,
	},
}
</script>

<style>
.bottom_form_navbar {
	background: #fff;
	position: fixed;
	bottom: 0;
	left: 0;
	right: 0;
	width: 100%;
	z-index: 9;
    box-shadow: -5px 0 5px rgba(0,0,0,.05);
}
</style>
