<template>
	<OffcanvasInline
		@hide="$parent.modal_visible = false"
		:visible="visible">
		<p class="section_title_medium mb-3">{{ item.name }}</p>
		<p class="text-muted mb-3">{{ item.description }}</p>

		<p class="fw-bold mb-1">
			Отметьте спортсменов и дистанции, для которых нужно получить услугу:
		</p>

		<div class="athletes_checkboxes mb-3">
			 <UpsellAthleteAccordeon v-for="athlete in athleteGroups" :key="athlete.athlete_id" :additional_service_id="item.id" :athlete_id="athlete.athlete_id" :distances="athlete.distances" />
		</div>
		<button
			@click.prevent="$parent.modal_visible = false"
			class="btn btn-primary btn-primary-shadow btn-rounded mt-auto px-5">
			Сохранить
		</button>
	</OffcanvasInline>
</template>

<script>
import UpsellAthleteAccordeon from '../forms/upsell/UpsellAthleteAccordeon.vue'
import OffcanvasInline from './OffcanvasInline.vue'
import {mapGetters} from 'vuex'
export default {
	props: {
		visible: {
			type: Boolean,
		},
		item: {
			type: Object,
			default: {},
		},
	},
    computed: {
        currentOrderItems(){
            return this.DISTANCE_ITEMS.filter(
				(item) => (item.is_payed || item.reservation_status == 'reserved') && 
                    (!this.item.for_distances || !this.item.for_distances.length || this.item.for_distances.indexOf(item.distance_length.toString()) >= 0)
			)
        },
        athleteGroups(){
            const groupedByAthleteId = this.currentOrderItems.reduce((acc, item) => {
                let group = acc.find(group => group.athlete_id === item.athlete_id);

                if (!group) {
                    group = { 
                        athlete_id: item.athlete_id,
                        distances: []
                    };
                    acc.push(group);
                }

                if(!group.distances.includes(item.distance_id)){
                    group.distances.push(item.distance_id);
                }

                return acc;
            }, []);

             return groupedByAthleteId
        },
        ...mapGetters({
            DISTANCE_ITEMS: 'order/DISTANCE_ITEMS'
        }),
    },
	components: {
		OffcanvasInline,
		UpsellAthleteAccordeon,
	},
}
</script>
