<template>
<div class="extended_check" :class="hasRelayItem ? 'active' : ''">
    <div class="row gy-y justify-content-between align-items-center">
        <div class="col">
            <div class="form-check mb-3 mb-md-0">
                <p v-if="item.swimstyle_name || item.distance_short_name" class="fw-bold text_20 mb-1">{{item.swimstyle_name ? item.swimstyle_name : item.distance_short_name}}</p>
                <p v-if="item.age_name" class="mb-0">{{item.age_name}}</p>
            </div>
        </div>
        <div class="ps-5 ps-md-0 col-md-3">
            <template v-if="hasRelayItem">
            <p class="text-muted small mb-1 text-center">Кол-во участников</p>
                <div class="input-group input-group-num">
                    <button @click.prevent="minus" :disabled="!hasRelayItem" type="button" :class="!hasRelayItem ? '' : 'text-body'">-</button>
                    <input :value="relayItem.quantity" type="number" class="form-control" min="0" onkeydown="return false">
                    <button @click.prevent="plus" :disabled="relayItem.quantity >= this.item.places_limit" type="button" :class="relayItem.quantity >= this.item.places_limit ? '' : 'text-body'">+</button>
                </div>
            </template>
            <a v-else @click.prevent="plus" href="#" class="btn btn-primary btn-primary-shadow btn-rounded w-100">Добавить</a>
        </div>
    </div>
</div>
</template>

<script>
export default {
    props: {
        item: {
            type: Object,
            default: [],
        },
    },
    inject: {
        relay_items: {
			from: 'relay_items',
			default: () => [],
		},
        addRelayItem: {
            from: 'addRelayItem',
            required: true
        },
        deleteRelayItem: {
            from: 'deleteRelayItem',
            required: true
        },
        changeRelayItemQuantity: {
            from: 'changeRelayItemQuantity',
            required: true
        },
    },
    computed: {
        relayItemKey(){
            return `relay__${this.item.id}`
        },
        relayItem(){
            let relay_item = this.relay_items.find(item => item.key == this.relayItemKey)
            if(!relay_item){
                relay_item = {
                    order_item_id: null,
                    key: this.relayItemKey,
                    distance_id: this.item.id,
                    quantity: 1,
                }
            }

            return relay_item
        },
        hasRelayItem(){
            return this.relay_items.some(item => item.key == this.relayItemKey)
        },
    },
    methods:{
        plus(){
            if(!this.hasRelayItem) {
                this.addRelayItem(this.relayItem)
                return
            }
            if(this.relayItem.quantity >= this.item.places_limit) return
            this.changeRelayItemQuantity(this.relayItemKey, this.relayItem.quantity + 1)
        },
        minus(){
            if(!this.hasRelayItem) return
            if(this.relayItem.quantity > 1){
                this.changeRelayItemQuantity(this.relayItemKey, this.relayItem.quantity - 1)
                return
            }
            this.deleteRelayItem(this.relayItemKey)
        },
    },
}
</script>

<style>

</style>
