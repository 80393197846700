<template>
	<div v-if="athlete" class="athlete_checkboxes__item mb-2 bg-body rounded cursor-pointer">
		<div
            @click="is_athlete_opened = !is_athlete_opened"
			class="athlete_checkboxes__header d-flex justify-content-between align-items-center p-3">
			<div class="table_name_item d-flex align-items-center">
				<Avatar :name="athlete.name" :src="athlete.avatar" :class="'me-2'" />
				<div class="name">
					<span class="fw-bold">{{ athlete.name }}</span>
					<span class="d-block text-muted small">{{ athlete.birthdate }} г.р</span>
				</div>
			</div>
            <vue-feather :type="is_athlete_opened ? 'chevron-up' : 'chevron-down'" class="text-muted" />
		</div>

		<transition name="slide">
			<div
				v-if="is_athlete_opened"
				class="athlete_checkboxes__distancies px-3">
					<UpsellDistanceCheckbox
                        v-for="distance_id in distances" 
                        :key="distance_id"
						:distance_id="distance_id"
                        :athlete_id="athlete_id"
                        :additional_service_id="additional_service_id"
                    />
			</div>
		</transition>
	</div>
</template>

<script>
import UpsellDistanceCheckbox from './UpsellDistanceCheckbox.vue'
import Avatar from '../../UI/Avatar.vue'
import {mapGetters} from 'vuex'
export default {
    props: {
        additional_service_id: {
            type: [Number, String],
            required: true,
        },
        athlete_id: {
            type: [Number, String],
            required: true,
        },
        distances: {
            type: Array,
            default: () => []
        },
    },
    data(){
        return {
            is_athlete_opened: false,
        }
    },
    computed: {
        athlete(){
            return this.ATHLETES.find(item => item.id == this.athlete_id)
        },
        ...mapGetters({
            ATHLETES: 'athletes/getAthletes'
        })
    },
    components: {
        UpsellDistanceCheckbox,
        Avatar,
    }
}
</script>
<style scoped>
.athlete_checkboxes__header {
	background: #e9ebf1;
	border-radius: 5px;
}

.athlete_checkboxes__distancies {
	background: #f5f6fa;
	padding-top: 5px;
	margin-top: -5px;
}

.athlete_checkboxes__distancies__item {
	border-bottom: 1px solid #e9ebf1;
}

.athlete_checkboxes__distancies__item:last-child {
	border: none;
}

.athlete_checkboxes__header .form-check-input:checked {
	background-color: #071031;
	border-color: #071031;
}

.athlete_checkboxes__distancies .form-check-input:checked {
	background-color: #28c76f;
	border-color: #28c76f;
}

.slide-enter-active,
.slide-leave-active {
	transition: max-height 0.2s ease-in-out;
}

.slide-enter-to,
.slide-leave-from {
	overflow: hidden;
	max-height: 1000px;
}

.slide-enter-from,
.slide-leave-to {
	overflow: hidden;
	max-height: 0;
}
</style>
