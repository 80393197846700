<template>
<OffcanvasInline id="add_documents" @show="showModal" @hide="$emit('hide'), visible = !visible" :visible="visible">
<p class="section_title_medium mb-4">Добавить документы</p>
<div class="mb-4">
<div class="mb-2">
    <UploadDocuments
        :required_documents="form_data.required_documents" 
        :is_active="true"
        v-model="form_data.documents"
    />
</div>
</div>

<button @click.prevent="postDocuments" class="btn btn-primary btn-primary-shadow btn-rounded mt-auto px-5">Сохранить</button>

</OffcanvasInline>
</template>

<script>
import OffcanvasInline from './OffcanvasInline.vue'
import UploadDocuments from '../forms/UploadDocuments'
import UploadAvatar from '../forms/UploadAvatar.vue'
import axios from "axios"
export default {
    props:{
        visible: {
            type: Boolean,
            default: false
        },
        documents_data: {
            type: Object,
            default: () => {
                id: null
                documents: []
                required_documents: []
            }
        }
    },
    data(){
        return {
            form_data: {
                id: null,
                documents: []
            },
            error_message: null
        }
    },
    methods: {
        async postDocuments(){
            await axios.post(process.env.VUE_APP_BASE_URL+'athlete/documents/'+this.form_data.id+'/edit', this.getFormData() ,{
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Accept': 'application/json',
                    'authorization': `Bearer ${localStorage.getItem('access_token')}`
                }
            }).then(res => {
                if(res.data && res.data?.success){                  
                    this.$swal('Изменения сохранены', '', 'success')
                    this.$store.commit('athletes/setDocuments', {
                        athlete_id: res.data.athlete_id, 
                        documents: res.data.documents, 
                    })
                    //this.$emit('changeData', res.data?.athlete_data)
                    this.$emit('hide')
                } else {
                    this.$swal('Неизвестная ошибка, попробуйте еще раз', '', 'error')
                }
            }).catch(error => {
                console.log(error)
                this.error_message = error.response.data.message
                this.$swal(error.response.data.message, '', 'error')
            })
        },
        getFormData(){
            let fd = new FormData();
            if(this.form_data.documents){
                for(var i = 0; i < this.form_data.documents.length; i++){
                    if(this.form_data.documents[i].document && typeof this.form_data.documents[i].document == 'object'){
                        fd.append('documents['+i+'][name]', this.form_data.documents[i].name)
                        for (let n = 0; n < this.form_data.documents[i].document.length; n++) {

                            let file = this.form_data.documents[i].document[n];
                            
                            if(this.form_data.documents[i].document instanceof FileList){
                                file = this.form_data.documents[i].document.item(n);
                            }
                            
                            fd.append('documents['+i+'][document][]', file)
                        }
                    }
                }
            }
            return fd
        },
        showModal(){
            this.clearForm()
            if(this.documents_data){
                this.form_data = Object.assign(this.form_data, this.documents_data)
            }
        },
        clearForm(){
            
            this.form_data = {
                id: null,
                documents: []
            }
            console.log(this.form_data);
        }
    },
    emits: ['changeData', 'hide'],
    components: {
        UploadDocuments,
        UploadAvatar, OffcanvasInline
    },
}
</script>

<style>
</style>
