<template>
	<div class="inputgroup_distance col-12 row g-2" :class="'key_' + index">
		<div class="col-md-6">
			<label class="d-block">
				<span class="fw-bold">Выберите дистанцию <span class="text-danger">*</span></span>
                <VSelect
                    v-model="theModel.id"
                    :options="sortedAllowedDistances"
                    label="distance_name"
                    :selectable="(option) => !isDistanceDisabled(option.id)"
                    :searchable="false"
                    :disabled="!!theModel.order_item_id"
                    :reduce="(option) => option.id"
                    @option:selected="changeDistance"
                    placeholder="Выберите вариант"
                    :class="show_errors && !theModel.id ? 'invalid' : ''">
                    <template v-slot:option="option">
                        {{ option.distance_name }}<br>
                        <span v-if="isDistanceDisabled(option.id)" class="small">Нет мест</span>
                    </template>
                    <template #no-options>
                        <div>Нет подходящих вариантов</div>
                    </template>
                </VSelect>
				<p v-if="show_errors && !theModel.id" class="mb-0 small text-danger">
					Обязательное поле
				</p>
			</label>
		</div>
		<div v-if="theModel.id" class="col-md-3">
			<label for="athlet_time" class="d-block">
				<span class="fw-bold">Заявочное время</span>
			</label>
			<div class="d-flex align-items-center">
				<input
					v-model="theModel.entrytime"
					type="text"
					class="form-control me-3"
					placeholder="мм:сс.мс"
					v-maska="'##:##.##'"
					:class="{
						invalid: show_errors && (!theModel.entrytime || theModel.entrytime.length < 8),
					}" />
				<!-- <span v-if="theModel.system_entrytime" class="text-muted system_entrytime_text small">
					Заявочное время нельзя изменить, так как использованы результаты из базы данных
				</span> -->
			</div>
			<p
				v-if="show_errors && !theModel.system_entrytime && !theModel.entrytime"
				class="mb-0 small text-danger">
				Обязательное поле
			</p>
		</div>
        <div v-if="theModel.id" class="col-md-2">
            <div class="d-flex flex-column h-100">
			<span class="fw-bold">Статус</span>
            <div class="flex-grow-1 d-flex flex-column align-items-start justify-content-center my-1">
                <DistanceStatus
                    :status="theModel.reservation_status"
                    :is_payed="theModel.is_payed"
                />
            </div>
         </div>
        </div>
		<div v-if="delete_btn" class="col-auto d-flex align-items-end pb-1">
			<button
				@click.prevent="deleteDistance"
				class="btn btn-circle bg-white text-muted ms-auto mb-2">
				<vue-feather type="trash-2" />
			</button>
		</div>
	</div>
</template>

<script>
import DistanceStatus from './DistanceStatus.vue'
import { computed } from 'vue'
import { mapGetters, mapActions, mapMutations } from 'vuex'
import { distanceTemplate } from '@/helpers/formTemplates'
import VSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'

export default {
	props: {
		allowedDistances: {
			type: Array,
			default: () => [],
		},
		used_distances: {
			type: Array,
			default: () => [],
		},
		index: {
			type: Number,
			required: true,
		},
		athlete: {
			type: Object,
			default: () => null,
		},
		delete_btn: {
			type: Boolean,
			default: false,
		},
		modelValue: {
			type: Object,
			default: () => ({}),
		},
	},
	inject: {
		show_errors: {
			from: 'show_errors',
			default: false,
		},
		distances_places: {
			from: 'distances_places',
			default: () => [],
		},
	},
	computed: {
        distance(){
            if(!this.theModel.id) return
            return this.getDistance(this.theModel.id)
        },
		distanceKey() {
			if (this.theModel.order_item_id && this.theModel.key) return this.theModel.key
			if (!this.athlete?.id || !this.theModel.id) return null
			return this.athlete?.id + '__' + this.theModel.id
		},
        hasDistancePlaces(){
            if(!this.distance) return false
            return this.distance.places_reserved < this.distance.places_limit
        },
        disabledDistances(){
            return this.distances_places.filter(item => item.places_reserved >= item.places_limit).map(item => item.id)
        },
        sortedAllowedDistances(){
            return this.allowedDistances.sort((a,b) => a.distance - b.distance).sort((a,b) => this.isDistanceDisabled(a.id) - this.isDistanceDisabled(b.id))
        },
		...mapGetters({
			getDistance: 'events/getStageDistanceByID',
		}),
	},
	methods: {
		async changeDistance() {
			this.$parent.loading = true
			//await this.fetchDistancePlaces(this.theModel.id)
			if (this.hasDistancePlaces) {
				this.setEntrytime()
			} else {
				this.theModel = { ...distanceTemplate }
				this.$swal('К сожалению, в этой дистанции закончились места', '', 'warning')
			}
			this.$parent.loading = false
		},
        async deleteDistance(){
            if(!this.theModel?.order_item_id){
                this.$emit('removeDistance', index)
                return
            }
            this.$swal({
                title: "Вы уверены, что хотите удалить эту дистанцию?",
                icon: 'error',
                showCancelButton: true,
                confirmButtonText: "Удалить",
                confirmButtonColor: "#dc3545",
                cancelButtonText: "Отмена",
            }).then((result) => {
                if (result.isConfirmed) {
                    this.$parent.loading = true
                    this.deleteOrderItem(this.theModel.order_item_id)
                    this.$parent.loading = false
                }
            })
        },
		setEntrytime() {
			let entrytimes = this.athlete.best_results.filter(
				(item) =>
					item.stroke == this.distance.stroke &&
					item.distance == this.distance.distance &&
					item.gender == this.distance.gender
			)
			if (entrytimes.length) {
				if (entrytimes.length == 1) {
					this.theModel.entrytime = entrytimes[0].short_swimtime
				} else {
					this.theModel.entrytime = entrytimes.sort(
						(a, b) =>
							moment(a.short_swimtime, 'mm:ss.SS') - moment(b.short_swimtime, 'mm:ss.SS')
					)[0].short_swimtime
				}
				this.theModel.system_entrytime = true
			}
		},
		isDistanceDisabled(distance_id) {
			return (
				this.disabledDistances.includes(distance_id) ||
				this.used_distances.includes(distance_id)
			)
		},
		...mapActions({
			//fetchDistancePlaces: 'events/fetchDistancePlaces',
			deleteOrderItem: 'order/deleteOrderItem',
		}),
	},
	watch: {
		distanceKey(newVal) {
			this.theModel.key = newVal
		},
	},
	setup(props, { emit }) {
		const theModel = computed({
			get: () => props.modelValue,
			set: (value) => emit('update:modelValue', value),
		})
		return { theModel }
	},
	emits: ['changeDistance', 'removeDistance', 'update:modelValue'],
    components: {
        VSelect,
        DistanceStatus
    }
}
</script>

<style>
.system_entrytime_text {
	font-size: 12px;
}
</style>
