<template>
	<div
		v-if="!!distance"
        @click="changeCheckbox"
		class="athlete_checkboxes__distancies__item d-flex justify-content-between py-3 cursor-pointer">
		<span
			class="fw-bold"
			:class="checked ? 'text-success' : ''"
			>{{ distance.distance_name }}</span
		>
		<input
            :checked="checked"
			type="checkbox"
			class="form-check-input ml-2" />
	</div>
</template>

<script>
import {mapGetters} from 'vuex'
export default {
    props: {
        additional_service_id: {
            type: Number,
            required: true
        },
        athlete_id: {
            type: Number,
            required: true
        },
        distance_id: {
            type: Number,
            required: true
        },
    },
    inject: {
        extra_items: {
			from: 'extra_items',
			default: () => [],
		},
        addExtraItem: {
            from: 'addExtraItem',
            required: true
        },
        deleteExtraItem: {
            from: 'deleteExtraItem',
            required: true
        },
    },
    computed: {
        extraItemKey(){
            return `${this.additional_service_id}__${this.athlete_id}__${this.distance_id}`
        },
        extraItem(){
            let extra_item = this.extra_items.find(item => item.key == this.extraItemKey)
            if(!extra_item){
                extra_item = {
                    order_extra_item_id: null,
                    is_payed: false,
                    additional_service_id: this.additional_service_id,
                    quantity: 1,
                    athlete_id: this.athlete_id,
                    distance_id: this.distance_id,
                    key: this.extraItemKey,
                }
            }

            return extra_item
        },
        distance(){
            return this.DISTANCES.find(item => item.id == this.distance_id)
        },
        checked(){
            return this.extra_items.some(item => item.key == this.extraItemKey)
        },
        ...mapGetters({
            DISTANCES: 'events/DISTANCES'
        }),
    },
    methods: {
        changeCheckbox(){
            if(!this.checked) {
                this.addExtraItem(this.extraItem)
                return
            }
            this.deleteExtraItem(this.extraItemKey)
        },
    },
}
</script>

<style></style>
