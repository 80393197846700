<template>
	<template v-if="service">
		<div
			v-if="service.type == 'for_athletes' || service.type == 'for_distances'"
			class="orders__item orders__item--extended">
			<div class="d-flex justify-content-between align-items-center mb-3">
				<p class="product_name fw-bold mb-0">{{ service.name }}</p>
				<p class="price text-success fw-bold mb-0">
					{{ items.length }} x {{ service.price }} ₽
				</p>
			</div>
			<OrderListServiceItemExpand
				v-for="(elem, i) in itemsGroupByAthleteId"
				:key="i"
				:items="elem" />
		</div>
		<div v-else class="orders__item">
			<p class="product_name fw-bold mb-0">{{ service.name }}</p>
			<p class="price text-success fw-bold mb-0">
				{{ items[0].quantity }} x {{ service.price }} ₽
			</p>
		</div>
	</template>
</template>

<script>
import OrderListServiceItemExpand from './OrderListServiceItemExpand'
import { mapGetters } from 'vuex'

export default {
	props: {
		items: {
			type: Array,
			required: true,
		},
	},
	computed: {
		service() {
			if (!this.STAGE?.extra_services) return null
			return this.STAGE.extra_services.find(item => item.id == this.items[0].additional_service_id)
		},
        itemsGroupByAthleteId(){
            return _.chain(this.items)
                .groupBy(item => {
                    return `${item.athlete_id}`
                })
        },
		...mapGetters({
			STAGE: 'events/getFormStagePost',
		}),
	},
	components: { OrderListServiceItemExpand },
}
</script>

<style></style>
