<template>
	<div class="inputgroup_athlet content_box p-4 mb-4">
		<button
			@click.prevent="$emit('remove', index)"
			v-if="delete_btn"
			class="btn btn-circle text-muted mb-2 ms-auto">
			<vue-feather type="trash-2" />
		</button>
		<div class="row g-3">
			<AthleteSelect
				v-model="theModel.athlete_id"
                :athlete="athlete"
                :hasDistances="used_distances.length > 0"
				@selectAthlete="selectAthlete" />

			<div v-if="theModel.athlete_id && this.required_documents" class="col-sm-6 col-xxl-3">
				<div class="form-check mt-4">
					<input
						v-model="theModel.online_request"
						class="form-check-input"
						:id="'onlide_docs_' + index"
						type="checkbox"
						value="1" />
					<label class="form-check-label small" :for="'onlide_docs_' + index">
						Предоставить документы для онлайн-допуска
					</label>
				</div>
			</div>

			<div
				v-if="theModel.athlete_id"
				class="col-sm-6 col-xxl-3 d-flex justify-content-sm-end">
				<div v-if="theModel.online_request" class="docs_checker">
					<p class="fw-bold mb-1">Документы</p>
					<div class="docs_checker__cont d-flex">
						<template v-if="!isDocumentsFull">
							<div class="alert alert-xs alert-danger bg-body mb-0 me-2">
								<span class="alert-title">Недостаточно</span>
							</div>
							<button
								@click.prevent="visible_modals.edit_documents = true"
								class="btn btn-sm btn-primary btn-primary-shadow">
								Добавить
							</button>
						</template>
						<template v-else>
							<div class="alert alert-xs alert-success bg-body mb-0 me-2">
								<span class="alert-title">Добавлены</span>
							</div>
							<button
								@click.prevent="visible_modals.edit_documents = true"
								class="btn btn-sm btn-primary btn-primary-shadow">
								Посмотреть
							</button>
						</template>
					</div>
					<p
						v-if="show_errors && theModel.online_request && !isDocumentsFull"
						class="mb-0 small text-danger">
						Необходимо добавить документы
					</p>
				</div>
				<AddDocuments
					:visible="visible_modals.edit_documents"
					:documents_data="documentOffcanvasData"
					@hide="visible_modals.edit_documents = false" />
			</div>

			<div
				v-if="theModel.athlete_id && !theModel.online_request && offline_request_text"
				class="col-12">
				<div
					v-html="offline_request_text"
					class="alert alert-warning bg-body mb-0 me-2"></div>
			</div>

			<template v-if="theModel.athlete_id && DISTANCES.length">
				<AthleteDistance
					v-for="(distance, i) in theModel.distances"
					:key="distance.key"
					v-model="theModel.distances[i]"
                    :athlete="athlete"
					:index="i"
					:delete_btn="used_distances.length > 0"
					:allowedDistances="allowedDistances"
					:used_distances="used_distances"
					@removeDistance="removeDistance" />
			</template>
			<div v-if="theModel.athlete_id && DISTANCES.length" class="col-12">
				<button
					@click.prevent="addDistance(theModel.distances.length)"
					class="btn btn-warning btn-sm">
					Добавить дистанцию
				</button>
			</div>
		</div>

		<div v-if="loading" class="loading_overlay">
			<CSpinner color="primary" />
		</div>
	</div>
</template>

<script>
import AthleteDistance from './AthleteDistance'
import AthleteSelect from './AthleteSelect'
import AddDocuments from '../offcanvases/AddDocuments.vue'
import { CSpinner } from '@coreui/vue'
import { computed } from 'vue'
import { mapGetters, mapActions, mapMutations } from 'vuex'
import moment from 'moment'
import {distanceTemplate} from '@/helpers/formTemplates'

export default {
	props: {
		modelValue: {
			type: Object,
			default: () => ({}),
		},
		index: {
			type: Number,
		},
		delete_btn: {
			type: Boolean,
			default: false,
		},
		has_errors: {
			type: Boolean,
			default: () => false,
		},
	},
    inject: {
        show_errors: {
            from: 'show_errors',
            default: false
        },
        required_documents: {
            from: 'required_documents',
            default: () => [],
        },
        used_athletes: {
            from: 'used_athletes',
            default: () => [],
        },
        age_limits: {
            from: 'age_limits',
            default: () => [],
        },
        agedata_value: {
            from: 'agedata_value',
            default: null,
        },
        event_stage_id: {
            from: 'event_stage_id',
        },
        offline_request_text: {
            from: 'offline_request_text',
            default: null,
        }
    },
	data() {
		return {
			distance_metadata: {
				selected_athlete: null,
				selected_distances: [],
			},
			visible_modals: {
				edit_athlete: false,
				edit_documents: false,
			},
            loading: false,
		}
	},
	watch: {
		theModel: {
			handler(newValue, oldValue) {
				this.validateFields()
			},
			deep: true
		}
	},
	computed: {
        used_distances(){
            return this.theModel.distances.map(item => item.id).filter(item => !!item)
        },
        athlete(){
            if(!this.theModel?.athlete_id) return
            return this.ATHLETES.find(item => item.id == this.theModel?.athlete_id)
        },
        athlete_event_documents(){
            if(!this.athlete || !this.required_documents || !this.required_documents.length) return [];
            let event_documents = [];
            this.required_documents.forEach((name) => {
                let athlete_doc = this.athlete.documents.find((item) => {
                    return item.name === name
                })
                if (athlete_doc) {
                    event_documents.push({
                        id: athlete_doc.id ? athlete_doc.id : null,
                        name: name,
                        document: athlete_doc.document,
                    })
                } else {
                    event_documents.push({ 
                        id: null,
                        name: name, 
                        document: [] 
                    })
                }
            })

            return event_documents
        },
        isDocumentsFull(){
            let full = true

            this.athlete_event_documents.forEach((item) => {
                if(!item.id) full = false
            })

            return full;
        },
        documentOffcanvasData() {
			return {
				id: this.theModel.athlete_id,
				documents: this.athlete.documents,
				required_documents: this.required_documents,
			}
		},
		allowedDistances() {
			if(this.athlete && this.athlete.birthdate){

				let birthdate = moment(this.athlete.birthdate, 'DD/MM/YYYY')

                let years = moment().endOf('year').diff(birthdate, 'years')
                if(this.agedata_value){
                    years = moment(this.agedata_value).diff(birthdate, 'years')
                }
				
				return this.DISTANCES.filter((item) => {
					if (
						item.agemin <= years &&
						item.agemax >= years &&
						this.athlete.gender == item.gender
					)
						return true
				})
			}
			
		},
        ...mapGetters({
            ATHLETES: 'athletes/getAthletes',
            DISTANCES: 'events/DISTANCES'
		}),
	},
	
	mounted() {
		this.validateFields()
	},
	beforeUpdate() {
		this.validateFields()
	},
	methods: {
		selectAthlete() {
			if (!this.athlete) {
				this.clearData()
				return
			}
			//this.checkAthleteLimit()
			this.setTheModelData()
		},
		/* async checkAthleteLimit() {
			this.loading = true
			await this.fetchAgeLimits(this.event_stage_id)
            let agelimit = null;
            let column = 'male_years'
            if(this.athlete.gender == 'F'){
                column = 'female_years'
            }
            
            agelimit = this.age_limits.find(element => {
                return element[column].includes(this.athlete.birthdate.split('/')[2])
            });

            if(!agelimit || !agelimit?.has_vacant_places){
                this.$emit('disallowAthlete', this.athlete.id)
				this.clearData()
				this.$swal(
					'Превышен лимит участников в этой возрастной группе',
					'К сожалению, больше нельзя подавать заявки в этой возрастной группе',
					'error'
				)
            }

			this.loading = false
		}, */
		setTheModelData(item) {
			this.theModel.online_request = false
			if (this.DISTANCES.length) {
				this.theModel.distances = [{...distanceTemplate}]
			}
		},
		clearData() {
			this.theModel.athlete_id = null
			this.theModel.online_request = false
			if (this.DISTANCES.length) {
				this.theModel.distances = [{...distanceTemplate}]
			}
		},
		/* changeDocuments(athlete) {
			let check = false
			
			this.athletes.forEach((item) => {
				if (item.id === athlete.id) {
					item.documents = athlete.documents
					check = true
				}
			})
			if (!check) {
				this.allAthletes.forEach((item) => {
					if (item.id === athlete.id) {
						item.documents = athlete.documents
						check = true
					}
				})
			}
		}, */
		addDistance(key) {
			this.theModel.distances.push({...distanceTemplate})
		},
		removeDistance(index) {
			this.theModel.distances.splice(index, 1)
		},
		/* isDocumentsFull() {
			if(!this.athlete_documents) return false
			if(typeof this.theModel.athlete_documents == 'string') return true
			let exist = !this.theModel.athlete_documents.find((item) => {
				return item.document === null
			})
			if (exist) return true
			return false
		}, */
		validateFields() {
			if (
				!this.theModel.athlete_id ||
				(this.theModel.online_request && !this.isDocumentsFull) ||
				!this.validateDistances()
			) {
				this.has_errors = true
			} else {
				this.has_errors = false
			}
		},
		validateDistances() {
			let res = true
            this.theModel.distances.forEach((item) => {
                if (!item.id || !item.entrytime || item.entrytime.length < 8) res = false
            })
			return res
		},
		...mapActions({
            /* fetchAgeLimits: 'events/fetchAgeLimits', */
		}),
		...mapMutations({
			setDistanceDisabled: 'events/setDistanceDisabled',
		}),
	},
    setup(props, { emit }) {
		const theModel = computed({
			get: () => props.modelValue,
			set: (value) => emit('update:modelValue', value),
		})
		const has_errors = computed({
			get: () => props.has_errors,
			set: (value) => emit('update:has_errors', value),
		})
		return { theModel, has_errors }
	},
	emits: ['update:modelValue', 'update:has_errors', 'remove', 'disallowAthlete'],
	components: {
		AthleteDistance,
		AthleteSelect,
		AddDocuments,
		CSpinner,
	},
}
</script>

<style scoped>
.inputgroup_athlet {
	position: relative;
}

.loading_overlay {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	background: rgba(255, 255, 255, 0.8);
	display: flex;
	align-items: center;
	justify-content: center;
}
</style>