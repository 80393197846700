<template>
<div class="content_box p-4 mb-4">
    <div class="content_box__header d-flex justify-content-between align-items-center mb-3 position-relative z-2">
        <h2 class="title_block mb-2 text-muted">Состав заказа</h2>
    </div>
    <div class="orders">
        <template v-if="distancesOrderList.length">
            <div class="orders__item" v-for="(type, i) in distancesOrderList" :key="i">
                <p class="product_name fw-bold mb-0">Заявочный взнос за дистанцию {{type.distance}}м {{type.count == 'many' ? 'со скидкой' : ''}}</p>
                <p class="price text-success fw-bold mb-0">{{type.quantity}} x {{type.price}} ₽</p>
            </div>
        </template>
        
        <div v-if="relaysOrderList.quantity" class="orders__item">
            <p class="product_name fw-bold mb-0">Заявочный взнос за эстафеты</p>
            <p class="price text-success fw-bold mb-0">{{relaysOrderList.quantity}} x {{relaysOrderList.price}} ₽</p>
        </div>
        <template v-for="(items, i) in extraItemsOrderList" :key="i">
            <OrderListServiceItem 
                :items="items"
            />
        </template>
    </div>

    <div v-if="summ" class="orders__summ d-flex justify-content-between text_20 fw-bold mt-4">
        <span>Итого: </span>
        <span class="text-success">{{summ}} ₽</span>
    </div>
    <div v-if="already_paid" class="orders__summ d-flex justify-content-between text_20 fw-bold mt-2">
        <span>Оплачено: </span>
        <span>{{already_paid}} ₽</span>
    </div>
    <div v-if="extraPay && extraPay > 0" class="orders__summ d-flex justify-content-between text_20 fw-bold mt-2">
        <span>К оплате: </span>
        <span class="text-success">{{extraPay}} ₽</span>
    </div>
    
</div>
</template>

<script>
import OrderListServiceItem from './OrderListServiceItem'
export default {
    props: {
        postdata: {
            type: Object,
            default: {},
        },
        distances: {
            type: Array,
            default: []
        },
        relay_races: {
            type: Array,
            default: []
        },
        extra_items: {
            type: Array,
            default: []
        },
        already_paid: {
            type: Number,
            default: null
        },
        summ: {
            type: Number,
            default: null
        },
        entry_fee: {
            type: Number,
            default: null
        },
    },
    computed: {
        distancesOrderList(){
            return _.chain(this.distances)
                .groupBy(item => {
                    return `${item.distance_length}-${item.price_type}`
                })
                .map(value => ({ distance: value[0].distance_length, count: value[0].price_type, quantity:value.length, price:value[0].price})).value()
        },
        relaysOrderList(){
            let count = 0
            this.relay_races.forEach(el => {
                if(el.quantity > 0){
                    count += el.quantity
                }
            })
            return {
                quantity: count,
                price: this.postdata.relay_price ?? 0
            }
        },
        extraItemsOrderList(){
            return _.chain(this.extra_items)
                .groupBy(item => {
                    return item.additional_service_id
                })
        },
        extraPay(){
            if(this.already_paid){
                return this.summ - this.already_paid
            }
            return null
        },
        
    },
    components: { OrderListServiceItem },
}
</script>

<style>

</style>
