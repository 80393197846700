<template>
	<h2 v-if="title" class="section_title_medium">{{ title }}</h2>
	<p v-if="subtitle" class="text-muted">{{ subtitle }}</p>
	<div class="position-relative">
		<AthletFieldgroup
			v-for="(item, index) in theModel"
            v-model="theModel[index]"
			v-model:has_errors="has_errors"
			:index="index"
			:key="index"
			:delete_btn="theModel.length > 1 ? true : false"
            :check_age_limit="true"
			@remove="removeAthleteFieldGroup"
            @disallowAthlete="disallowAthlete" />

		<div v-if="!is_loaded" class="loading_overlay">
			<CSpinner color="primary" />
		</div>
	</div>

	

	<button @click.prevent="addAthletFieldgroup" class="btn btn-secondary">
		<vue-feather type="plus-circle" /> Добавить спортсмена
	</button>
</template>

<script>
import AthletFieldgroup from '../../components/forms/AthletFieldgroup.vue'
import { mapGetters, mapActions } from 'vuex'
import { computed } from 'vue'
import { CSpinner } from '@coreui/vue'
import {itemTemplate} from '@/helpers/formTemplates'
export default {
	props: {
		modelValue: {
			type: Array,
			default: () => [],
		},
		has_errors: {
			type: Boolean,
			default: () => false,
		},
		title: {
			type: String,
			default: null,
		},
		subtitle: {
			type: String,
			default: null,
		},
	},
	data() {
		return {
            localdisallowed_athletes: [],
			selected_athletes: [],
			is_loaded: false,
		}
	},
	computed: {
		distances_places(){
            return this.DISTANCES.map(distance => {
                let distance_used = 0;
                this.theModel.forEach(fieldgroup => {
                    distance_used = fieldgroup.distances.filter(item => item.id == distance.id).length
                })

                return {
                    id: distance.id,
                    places_limit: distance.places_limit,
                    places_reserved: distance.places_reserved + distance_used,
                }
            })
        },
        used_athletes() {
			return this.theModel.map((item) => item.athlete_id).filter((item) => !!item)
		},
        ...mapGetters({
			getAthleteByID: 'athletes/getAthleteByID',
            DISTANCES: 'events/DISTANCES',
		}),
	},
    provide() {
        return {
            localdisallowed_athletes: computed(() => this.localdisallowed_athletes),
            distances_places: computed(() => this.distances_places),
            used_athletes: computed(() => this.used_athletes),
        }
    },
	async mounted() {
        if(this.used_athletes.length){
            /*TODO: Добавить чужих атлетов в данные заказа на сервере, убрать эту функцию*/
            await this.fetchAthletesByIds(this.used_athletes)
        }
		//this.insertAthletesInfo()
        this.is_loaded = true
	},
	methods: {
		...mapActions({
			fetchAthletesByIds: 'athletes/fetchAthletesByIds',
		}),
        disallowAthlete(athlete_id){
            if(!this.localdisallowed_athletes.includes(athlete_id)){
                this.localdisallowed_athletes.push(athlete_id)
            }
        },
		/* insertAthletesInfo() {
			this.theModel.forEach((item) => {
				if (item.athlete_id) {
					item.athlete = this.getAthleteByID(item.athlete_id)
				}
			})
		}, */
		addAthletFieldgroup() {
			if (this.DISTANCES.length) {
				this.theModel.push({...itemTemplate})
			}
		},
		removeAthleteFieldGroup(e) {
			this.theModel.splice(e, 1)
		},
	},
	setup(props, { emit }) {
		const theModel = computed({
			get: () => props.modelValue,
			set: (value) => emit('update:modelValue', value),
		})
		const has_errors = computed({
			get: () => props.has_errors,
			set: (value) => emit('update:has_errors', value),
		})
		return { theModel, has_errors }
	},
	emits: ['update:modelValue', 'update:has_errors'],
    components: { AthletFieldgroup, CSpinner },
}
</script>

<style scoped>
.loading_overlay {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	background: rgba(255, 255, 255, 0.8);
	display: flex;
	align-items: center;
	justify-content: center;
}
</style>