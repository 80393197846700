<template>
<PageLoader v-if="!is_loaded" />
<with-sidebar-layout v-if="is_loaded">
    <template #head>
        <PageTitle title="Заявка" :breadcrumbs="breadcrumbs"/>
    </template>
    <template #content>
        <OrderResultMessage 
            v-if="show_order_message"
            :status="orderdata.payment_status"
            class="mb-4" />
        <div class="content_box p-4 mb-4">
            <div class="content_box__header d-flex justify-content-between align-items-center mb-3 position-relative z-2">
                <h2 class="title_block mb-2 fs-3">Заявка #{{orderdata.id}}</h2>
            </div>
            <div class="row g-4">
                <div v-if="postData.name && postData.id" class="col-auto small">
                    <p class="text-muted mb-1">Мероприятие</p>
                    <router-link :to="`/stage/${postData.id}`" class="mb-0 fw-bold">
                    {{postData.name}}
                </router-link>
                </div>
                <div v-if="orderdata.sent_date" class="col-auto small">
                    <p class="text-muted mb-1">Дата заявки</p>
                    <p class="mb-0">{{orderdata.sent_date}}</p>
                </div>
                <div v-if="postData.location_address" class="col-auto small ">
                    <p class="text-muted mb-1">Место проведения</p>
                    <p class="mb-0">
                        {{postData.location_address}}
                    </p>
                </div>
                <div v-if="postData.dates" class="col-auto small">
                    <p class="text-muted mb-1">Сроки проведения</p>
                    <p class="mb-0">
                        {{postData.dates}}
                    </p>
                </div>
            </div>
        </div>

        <SportsmensTable 
            :distances="true"
            :documents="true"
        />
        <EstafetasTable 
            v-if="RELAY_ITEMS.length"
            :items="RELAY_ITEMS"
        />
        <AdditionalData 
            :club="{
                name: orderdata.club_name,
                shortname: orderdata.club_shortname,
                state: orderdata.club_state,
            }"
            :contacts="{
                name: orderdata.agent_name,
                phone: orderdata.agent_phone,
                email: orderdata.agent_email,
            }"
        />

        <p class="text-decorative fs-2 text-uppercase mb-2">Информация о заказе</p>
        <div class="row g-4">
            <div class="col-md-6">
                <OrdersList 
                    id="order_list"
                    :distances="DISTANCE_ITEMS"
                    :relay_races="RELAY_ITEMS"
                    :extra_items="EXTRA_ITEMS" 
                    :postdata="postData" 
                    :summ="orderdata.summ"
                    :already_paid="orderdata.already_paid"
                />
            </div>

            <div class="col-md-6">
                <RequestOrderInfo 
                    v-if="postData.first_status == 'pending_payment'"
                    :id="orderdata.id"
                    :payment_status="orderdata.payment_status"
                    :summ="orderdata.summ"
                    :payment_summ="paymentSumm"
                    :paytime_end="orderdata.paytime_end"
                    @send="toPayment()"
                    @time_end="setExpire"
                />
                <div v-if="orderdata.payers_name || orderdata.payers_phone || orderdata.payers_email" class="content_box p-4 mb-4">
                    <div class="content_box__header d-flex justify-content-between align-items-center mb-3 position-relative z-2">
                        <h2 class="title_block mb-2 text-muted">Плательщик</h2>
                    </div>
                    <table class="list_box__content">
                        <tr v-if="orderdata.payers_name">
                            <td class="text-muted">ФИО:</td>
                            <td class="ps-3">{{orderdata.payers_name}}</td>
                        </tr>
                        <tr v-if="orderdata.payers_phone">
                            <td class="text-muted">Телефон:</td>
                            <td class="ps-3">{{orderdata.payers_phone}}</td>
                        </tr>
                        <tr v-if="orderdata.payers_email">
                            <td class="text-muted">Email:</td>
                            <td class="ps-3">{{orderdata.payers_email}}</td>
                        </tr>
                    </table>
                </div>
                <div v-if="payment_method_desc" class="content_box p-4 mb-4">
                    <div class="content_box__header d-flex justify-content-between align-items-center mb-3 position-relative z-2">
                        <h2 class="title_block mb-2 text-muted">Способ оплаты</h2>
                    </div>
                    <p class="fw-bold mb-1">{{payment_method_desc.title}}</p>
                    <p class="text-muted small mb-0">{{payment_method_desc.desc}}</p>
                </div>
            </div>

        </div>
    </template>
    <template #sidebar>
        <RequestSidebarBox 
            :payment_status="orderdata.status"
            :first_status="postData.first_status"
            :summ="paymentSumm"
            :paytime_end="orderdata.expiration_time"
            @send="toPayment()"
            @time_end="setExpire"
        />
    </template>
</with-sidebar-layout>
</template>

<script>
import PageLoader from '../components/PageLoader.vue'
import OrderResultMessage from '../components/OrderResultMessage'
import PageTitle from '../components/PageTitle.vue'
import PageAlertBox from '../components/PageAlertBox.vue'
import RequestOrderInfo from '../components/RequestOrderInfo.vue'
import OrdersList from '../components/request/OrdersList.vue'
import SportsmensTable from '@/components/request/SportsmensTable.vue'
import RequestSidebarBox from '../components/RequestSidebarBox.vue'
import WithSidebarLayout from '../layouts/WithSidebarLayout.vue'
import EstafetasTable from '@/components/request/EstafetasTable.vue'
import AdditionalData from '@/components/request/AdditionalData.vue'
import {
    mapGetters, mapActions
} from "vuex"
export default {
    props: {
        postData: {
            type: Object,
            default: {}
        },
        orderdata: {
            type: Object,
            default: {}
        },
    },
    data(){
        return {
            payment_method_desc: null,
            breadcrumbs: [{
                title: 'Мой аккаунт',
                link: '/profile'
            }],
            show_order_message: false,
            is_loaded: false
        }
    },
    computed: {
        
        paymentSumm(){
            if(this.orderdata.already_paid){
                return this.orderdata.summ - this.orderdata.already_paid
            }
            return this.orderdata.summ
        },
        otherAthleteIds(){
            let exist_athletes = this.ATHLETES.map(athlete => athlete.id)
			return this.DISTANCE_ITEMS.map(item => {
				return item.athlete_id
			}).filter(athlete_id => !exist_athletes.includes(athlete_id))
		},
        ...mapGetters({
            ATHLETES: 'athletes/getAthletes',
            DISTANCE_ITEMS: 'order/DISTANCE_ITEMS',
            RELAY_ITEMS: 'order/RELAY_ITEMS',
            EXTRA_ITEMS: 'order/EXTRA_ITEMS',
            STAGE: "events/getFormStagePost",
            payment_methods: 'global/getPaymentMethods',
        })
    },
    async mounted(){
        if(this.otherAthleteIds.length){
            await this.fetchAthletesByIds(this.otherAthleteIds)
        }
        await this.checkPaymentStatus()
        this.is_loaded = true
    },
    methods: {
        async toPayment(){
            this.$swal({
                title: 'Перенаправление на форму оплаты',
                text: 'Пожалуйста, не закрывайте страницу',
                icon: 'info',
                allowOutsideClick: false,
                allowEnterKey: false,
                allowEnterKey: false,
                showConfirmButton: false,
                didOpen: () => {
                    this.$swal.showLoading()
                },
            })
			let res = await this.getPaymentUrl(this.orderdata.id)
			if (res?.url) {
				window.location.href = res.url
			} else {
				this.$swal.close()
				this.$swal('Возникла неизвестная ошибка, попробуйте еще раз', '', 'error')
			}
        },
        async setExpire() {
            await this.expireOrder(this.orderdata.id)
			this.$swal(
				'Время бронирования истекло',
				'Неоплаченные дистанции сняты с резерва',
				'warning'
			)
            this.activeStep = 'distances'
		},
        async checkPaymentStatus(){
            if(this.postData.first_status == 'pending_payment' && this.orderdata.payment_status == 'pending'){
                let res = await this.fetchPaymentStatus(this.orderdata.id)
                console.log(res);
                this.show_order_message = true
            }
        },
        ...mapActions({
            fetchAthletesByIds: "athletes/fetchAthletesByIds",
            fetchPaymentStatus: 'order/fetchPaymentStatus',
            getPaymentUrl: 'order/getPaymentUrl',
            expireOrder: 'order/expireOrder',
        }),
    },
    components: {
        OrderResultMessage,
        WithSidebarLayout,
        RequestSidebarBox,
        SportsmensTable,
        EstafetasTable,
        AdditionalData,
        OrdersList,
        RequestOrderInfo, PageAlertBox, PageTitle,
        PageLoader
    },
}
</script>

<style>
</style>
